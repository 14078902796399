import { useModals } from "@mantine/modals";
import { useTableProvider } from "../../providers";

import { ButtonComponent, ModalComponent } from "components";
import { Plus } from "tabler-icons-react";

export function AddNewBrandButtonComponent() {
  const modals = useModals();
  const { refreshTable } = useTableProvider();

  function handleClick() {
    modals.openContextModal("brand.create", {
      title: <ModalComponent.Title>Add New Brand</ModalComponent.Title>,
      innerProps: {
        onSubmit: async () => await refreshTable(),
      },
    });
  }

  return (
    <ButtonComponent onClick={handleClick} leftIcon={<Plus />}>
      Add New Brand
    </ButtonComponent>
  );
}
