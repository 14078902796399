import { DateTime } from "luxon";
import { Properties, Constructor } from "./types";
import {
  CourierModel,
  CourierServiceModel,
  DestinationLocationModel,
  DeviceDeliveryInformationModel,
  InternalLocationModel,
  OriginLocationModel,
  SerialNumberModel,
  SparePartModel,
} from "models";

export interface PickupRequestModel extends Properties {}
export class PickupRequestModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(resp: Record<string, any>) {
    return new PickupRequestModel({
      id: resp.id,
      devices: resp.devices.map((device: Record<string, any>) => ({
        serialNumberId: device.serial_number_id,
        serialNumber: SerialNumberModel.fromResponse(device.serial_number),
        partIds: device.part_ids,
        parts: device.parts.map((part: Record<string, any>) =>
          SparePartModel.fromResponse(part)
        ),
      })),
      complaint: resp.complaint,
      deviceDeliveryInformations: resp.device_delivery_informations.map(
        (deviceDeliveryInformation: Record<string, any>) =>
          DeviceDeliveryInformationModel.fromResponse(deviceDeliveryInformation)
      ),
      serviceCenterId: resp.service_center_id,
      serviceCenter: InternalLocationModel.fromResponse(resp.service_center),
      courierInformation: {
        courierId: resp.courier_information.courier_id,
        courier: CourierModel.fromResponse(resp.courier_information.courier),
        courierServiceId: resp.courier_information.courier_service_id,
        courierService: CourierServiceModel.fromResponse(
          resp.courier_information.courier_service
        ),
      },
      deliveryFeeInformation: {
        originId: resp.delivery_fee_information.origin_id,
        origin: OriginLocationModel.fromResponse(
          resp.delivery_fee_information.origin
        ),
        destinationId: resp.delivery_fee_information.destination_id,
        destination: DestinationLocationModel.fromResponse(
          resp.delivery_fee_information.destination
        ),
        weight: parseInt(resp.delivery_fee_information.weight),
        insuranceFee: parseInt(resp.delivery_fee_information.insurance_fee),
        deliveryFee: parseInt(resp.delivery_fee_information.delivery_fee),
      },
      senderInformation: {
        name: resp.sender_information.name,
        phoneNumber: resp.sender_information.phone_number,
        pickupLocationId: resp.sender_information.pickup_location_id,
        pickupLocation: OriginLocationModel.fromResponse(
          resp.sender_information.pickup_location
        ),
        address: resp.sender_information.address,
      },
      status: resp.status,
      createdAt: DateTime.fromISO(resp.created_at),
      updatedAt: DateTime.fromISO(resp.updated_at),
      deletedAt: resp.deleted_at && DateTime.fromISO(resp.deleted_at),
    });
  }
}
