import { DateTime } from "luxon";
import { Properties, Constructor } from "./types";

export interface CourierServiceModel extends Properties {}
export class CourierServiceModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(response: Record<string, any>) {
    return new CourierServiceModel({
      id: response.id,
      provider: response.provider,
      name: response.name,
      code: response.code,
      minWeight: parseInt(response.min_weight),
      maxWeight: parseInt(response.max_weight),
      createdAt: DateTime.fromISO(response.created_at),
      updatedAt: DateTime.fromISO(response.updated_at),
      deletedAt: response.deleted_at && DateTime.fromISO(response.deleted_at),
    });
  }
}
