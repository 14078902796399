import { Card, Stack } from "@mantine/core";
import { CardDataDisplayComponent } from "components";
import { usePickupRequest } from "hooks";
import { useParams } from "react-router";
import CurrencyUtils from "utils/currency";
import { SectionTitleComponent } from "..";

export function DeviceDeliveryInformationSection() {
  const { id } = useParams();
  const { pickupRequest } = usePickupRequest({ id });

  function formatWeight(num: number) {
    // Format number from grams to kg
    // And use ID locale
    const kg = num / 1000;
    const strKg = kg.toLocaleString("id-ID", { minimumFractionDigits: 3 });
    return `${strKg} kg`;
  }

  return (
    <Card>
      <Stack>
        <SectionTitleComponent>
          Device Delivery Information
        </SectionTitleComponent>
        <CardDataDisplayComponent
          label="Actual Weight"
          value={formatWeight(
            pickupRequest?.deviceDeliveryInformations[0].actualWeight ?? 0
          )}
        />
        <CardDataDisplayComponent
          label="Insured Value"
          value={CurrencyUtils.toCurrency(
            pickupRequest?.deviceDeliveryInformations[0].insuredValue ?? 0
          )}
        />
      </Stack>
    </Card>
  );
}
