import { Stack } from "@mantine/core";
import { DataProvider } from "./providers";
import { MainContentComponent } from "components";
import {
  AddNewButtonComponent,
  HeaderLeft,
  InvoiceItemsComponent,
  InvoiceMetadataComponent,
} from "./components";

export function AddNewPage() {
  return (
    <DataProvider>
      <MainContentComponent
        headerLeft={<HeaderLeft />}
        headerRight={<AddNewButtonComponent />}
      >
        <Stack>
          <InvoiceMetadataComponent />
          <InvoiceItemsComponent />
        </Stack>
      </MainContentComponent>
    </DataProvider>
  );
}
