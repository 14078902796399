import { Menu } from "@mantine/core";
import { RemoveMenuItemComponent } from "./RemoveMenuItem";
import { useDataProvider } from "../../providers";

export function TableItemsComponent() {
  const { data } = useDataProvider();

  return (
    <>
      {data.warranty?.map((w) => (
        <tr key={w.part.id}>
          <td>{w.part.name}</td>
          <td>{w.warrantyPeriod} months</td>
          <td>
            <Menu>
              <RemoveMenuItemComponent part={w.part} />
            </Menu>
          </td>
        </tr>
      ))}
    </>
  );
}
