import React from "react";
import validator from "validator";
import { SparePartModel } from "models";

import { Text, Box, Stack } from "@mantine/core";
import { SelectWarrantyModalProps } from "./types";
import { FormInput, ModalComponent, TextInputComponent } from "components";
import { useEffect, useState } from "react";

export function SelectWarrantyModal(props: SelectWarrantyModalProps) {
  const { context, id, innerProps } = props;
  const { onSubmit } = innerProps;

  const [isClean, setIsClean] = useState<boolean>(false);
  const [part, setPart] = useState<SparePartModel>();
  const [period, setPeriod] = useState<number | undefined>();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!isClean) return;
    if (period === undefined) return;
    if (!part) return;

    if (onSubmit) onSubmit({ part, period });
    context.closeModal(id);
  }

  function handlePeriodChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    if (validator.isEmpty(value)) return setPeriod(undefined);
    if (!validator.isNumeric(value)) return;

    return setPeriod(parseInt(value));
  }

  useEffect(() => {
    setIsClean(period !== undefined && period >= 0 && part !== undefined);
  }, [part, period]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <FormInput.SparePart value={part} onChange={setPart} />
        <TextInputComponent
          label="Warranty Period"
          value={period?.toString() ?? ""}
          rightSectionWidth={150}
          rightSection={<Text color="dimmed">months</Text>}
          onChange={handlePeriodChange}
        />
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          submit={{ label: "Add Coverage", disabled: !isClean }}
        />
      </Stack>
    </Box>
  );
}
