import { Navbar } from "@mantine/core";
import { MenuItemComponent } from "../MenuItem";
import { MenuSectionComponent } from "../MenuSection";
import { CustomNavbarComponentProps } from "./types";
import { FooterSectionComponent } from "../FooterSection";
import { MenuTitleComponent } from "../MenuTitle";

export function CustomNavbarComponent(props: CustomNavbarComponentProps) {
  const { children, opened } = props;

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ sm: 200, lg: 225 }}
      style={{ backgroundColor: "#000000" }}
    >
      {children}
    </Navbar>
  );
}

CustomNavbarComponent.MenuItem = MenuItemComponent;
CustomNavbarComponent.MenuTitle = MenuTitleComponent;

CustomNavbarComponent.MenuSection = MenuSectionComponent;
CustomNavbarComponent.FooterSection = FooterSectionComponent;
