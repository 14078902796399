import lodash from "lodash";
import { InvoiceModel } from "models";
import { UseInvoicesProps } from "./types";
import { fetcher } from "utils/net";

import useSWR from "swr";
import { useAuth } from "providers/auth";
import { useEffect, useState } from "react";

export function useInvoices(props?: UseInvoicesProps) {
  const limit = lodash(props).get("limit", 5);
  const offset = lodash(props).get("offset", 0);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [itemPerPage, setItemPerPage] = useState<number>(0);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);

  const { accessToken } = useAuth();
  const { data, error, isLoading, mutate } = useSWR(() => {
    if (!accessToken) return undefined;

    const baseUrl = `/invoices?limit=${limit}&offset=${offset}`;
    return [baseUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) return;

    const invoices = data.data.map((d: any) => InvoiceModel.fromResponse(d));
    setInvoices(invoices);

    // We need to set the totalCount
    // And itemPerPage based on the data.pagination.totalCount and data.pagination.limit
    if (!data.pagination) return;
    setTotalCount(data.pagination.total_count);
    setItemPerPage(data.pagination.limit);
  }, [data]);

  return {
    invoices: invoices,
    isLoading: isLoading,
    error: error,
    totalCount: totalCount,
    itemPerPage: itemPerPage,
    mutate: mutate,
  };
}
