import { DateTime } from "luxon";
import { Properties, Constructor } from "./types";

export interface PaymentLinkModel extends Properties {}
export class PaymentLinkModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(resp: Record<string, any>) {
    return new PaymentLinkModel({
      id: resp.id,
      url: resp.url,
      status: resp.status,
      createdAt: DateTime.fromISO(resp.created_at),
      updatedAt: DateTime.fromISO(resp.updated_at),
      deletedAt: resp.deleted_at && DateTime.fromISO(resp.deleted_at),
    });
  }
}
