import CurrencyUtils from "utils/currency";
import { useDataProvider } from "../../providers";

export function TableItemsComponent() {
  const { data } = useDataProvider();

  return (
    <>
      {data.items.map((i, index) => (
        <tr key={`${i.itemId}-${index}`}>
          <td>{i.itemId}</td>
          <td>{i.itemDescription}</td>
          <td>{i.qty}</td>
          <td>{CurrencyUtils.toCurrency(i.price)}</td>
          <td>{CurrencyUtils.toCurrency(i.qty * i.price)}</td>
        </tr>
      ))}
    </>
  );
}
