import { useModals } from "@mantine/modals";
import { useTableProvider } from "../../providers";

import { Menu } from "@mantine/core";
import { ModalComponent } from "components";

export function AddNewTransactionMenuItem() {
  const modals = useModals();
  const { refreshTable } = useTableProvider();

  function handleClick() {
    modals.openContextModal("warehouse-transaction.create", {
      title: (
        <ModalComponent.Title>
          Add New Warehouse Transaction
        </ModalComponent.Title>
      ),
      innerProps: {
        onSubmit: async () => await refreshTable(),
      },
    });
  }

  return <Menu.Item onClick={handleClick}>Add New Transaction</Menu.Item>;
}
