import React from "react";
import Papa from "papaparse";
import lodash from "lodash";
import { Box, SegmentedControl, Space, Stack, Text } from "@mantine/core";
import { FileInputComponent, ModalComponent } from "components";
import { DeviceModel } from "models";
import { ImportSerialNumberCsvModalProps } from "./types";
import { loadingComplete, showLoading } from "utils/loader";
import { net } from "utils/net";

import { useEffect, useState } from "react";
import { useAuth } from "providers/auth";

import {
  DeviceSelectForCreateComponent,
  ErrorAlertComponent,
  TemplateDownloadSectionComponent,
} from "./components";

export function ImportSerialNumberCsvModal(props: ImportSerialNumberCsvModalProps) {
  const { context, id, innerProps } = props;
  const { onSubmit } = innerProps;

  const [action, setAction] = useState<string>("create");
  const [selectedFile, setSelectedFile] = useState<File>();
  const [device, setDevice] = useState<DeviceModel>();
  const [isClean, setIsClean] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errFile, setErrFile] = useState<string>("");
  const { accessToken } = useAuth();

  function handleFileChange(file?: File) {
    if (!file) {
      setSelectedFile(undefined);
      return;
    }

    // We need to parse the file using papaparse,
    // and the check the header. This will minimise the workload on the server
    Papa.parse(file, {
      header: true,
      error: () => setSelectedFile(undefined),
      complete: (results) => {
        const acceptedHeaders = ["serial_number", "purchase_date"];
        const headers = lodash(results).get("meta.fields", []);
        if (!lodash(headers).isEqual(acceptedHeaders)) {
          const strAcceptedHeaders = acceptedHeaders.join(" ");
          const foundMessage = `Howerver, we found: ${headers.join(" ")} `;
          const errMessage = `First row must be headers with the following fields: ${strAcceptedHeaders}. ${foundMessage}`;
          setErrFile(errMessage);
          setSelectedFile(undefined);
        } else {
          setSelectedFile(file);
        }
      },
    });
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!accessToken) return;
    if (!isClean) return;
    if (!selectedFile) return;

    try {
      showLoading("serial-number.import-csv");
      setIsLoading(true);

      const netInstance = net(accessToken, {
        "Content-Type": "multipart/form-data",
      });

      const relUrl = `/serial-numbers/import-data/csv`;
      const formData = new FormData();

      if (device) formData.append("device_id", device.id);
      formData.append("file", selectedFile);

      if (action === "create") {
        // We will use async-import instead of import-data/csv
        const relUrl = `/serial-numbers/async-import/csv`;
        const resp = await netInstance.post(relUrl, formData);
        const jobId = lodash(resp).get("data.id");
        if (lodash(jobId).isNil() || lodash(jobId).isEmpty()) throw new Error("Invalid job id");

        const checkJobStatus = async () => {
          try {
            const statusResponse = await netInstance.get(`/async-jobs/${jobId}`);
            const status = lodash(statusResponse).get("data.status");
            if (status === "completed") {
              clearInterval(jobInterval);
              if (onSubmit) onSubmit();
              context.closeModal(id);
              loadingComplete("serial-number.import-csv", false);
            }
          } catch (err) {
            clearInterval(jobInterval);
            loadingComplete("serial-number.import-csv", true);
          }
        };

        const jobInterval = setInterval(checkJobStatus, 1000);
      } else if (action === "delete") {
        await netInstance.delete(relUrl, { data: formData });
        if (onSubmit) onSubmit();
        context.closeModal(id);
        loadingComplete("serial-number.import-csv", false);
      } else {
        throw new Error("Invalid action");
      }
    } catch (err) {
      loadingComplete("serial-number.import-csv", true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (action === "create") {
      setIsClean(
        selectedFile !== undefined &&
          selectedFile !== null &&
          device !== undefined &&
          device !== null
      );
    } else if (action === "delete") {
      setIsClean(selectedFile !== undefined && selectedFile !== null);
    }
  }, [selectedFile, device, action]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <ErrorAlertComponent errorMessage={errFile} />
        <Stack>
          <Text>Please select action</Text>
          <SegmentedControl
            size="md"
            color="primary"
            onChange={setAction}
            value={action}
            data={[
              { label: "Batch Create", value: "create" },
              { label: "Batch Delete", value: "delete" },
            ]}
          />
        </Stack>
        <TemplateDownloadSectionComponent action={action} />
        <DeviceSelectForCreateComponent action={action} device={device} setDevice={setDevice} />
        <Text>Lastly, please upload the template here.</Text>
        <FileInputComponent label="CSV File" acceptMime="text/csv" onChange={handleFileChange} />
        <Space />
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          cancel={{ disabled: isLoading }}
          submit={{ label: "Import CSV", disabled: !isClean || isLoading }}
        />
      </Stack>
    </Box>
  );
}
