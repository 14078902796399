import { createStyles } from "@mantine/core";
import { StyleProps } from "./types";

const useStyles = createStyles((theme, props: StyleProps, getRef) => ({
  innerInput: {
    color: "#212121",
    fontSize: 18,
    fontWeight: 400,
    height: 59,
    lineHeight: "27px",
    padding: "16px 24px"
  },
  input: {
    backgroundColor: "transparent",
    border: `2px solid ${(props.mainStyle === "light") ? "#E7E6EA" : "#A6A6A6"}`,
    height: 59
  },
  label: {
    color: "#212121",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "27px"
  }
}));

export default useStyles;