import { CardDataDisplayComponent } from "components";
import { useDevice } from "hooks";
import { DeviceModel } from "models";
import { DeviceComponentProps } from "./types";

export function DeviceComponent(props: DeviceComponentProps) {
  const { deviceId } = props;
  const { device } = useDevice({ deviceId });

  function generateValue(device?: DeviceModel) {
    if (!device) return "";
    return `${device.brand.name}, ${device.name}`;
  }

  return (
    <CardDataDisplayComponent label="Device" value={generateValue(device)} />
  );
}
