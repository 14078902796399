import { PaymentStatusBadgeComponent } from "components";
import { PaymentStatusComponentProps } from "./types";

import { usePaymentLink, usePickupRequestInvoices } from "hooks";

export function PaymentStatusComponent(props: PaymentStatusComponentProps) {
  const { pickupRequest } = props;
  const { invoices } = usePickupRequestInvoices({
    pickupRequestId: pickupRequest.id,
  });

  const { paymentLink } = usePaymentLink({
    paymentLinkId: invoices[0]?.paymentLinkId,
  });

  return <PaymentStatusBadgeComponent paymentLink={paymentLink} />;
}
