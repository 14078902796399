import { CardDataDisplayComponent } from "components";
import { SparePartModel } from "models";
import { PartsComponentProps } from "./types";

export function PartsComponent(props: PartsComponentProps) {
  const { parts } = props;

  function generateValue(parts?: SparePartModel[]) {
    if (!parts) return "";
    if (parts.length === 0) return "";
    return parts.map((part) => part.name).join(", ");
  }

  return (
    <CardDataDisplayComponent
      label="Device Checklist"
      value={generateValue(parts)}
    />
  );
}
