import { Group } from "@mantine/core";
import { TableComponent } from "components";

import { useTableProvider } from "../../providers";
import { useState } from "react";
import { FilterButtonComponent } from "../FilterButton";

export function TableHeadComponent() {
  const [sn, setSn] = useState<string>("");
  const { setFilter } = useTableProvider();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSn(e.target.value);
  }

  function handleClick() {
    setFilter({ serialNumber: sn });
  }

  return (
    <Group position="apart">
      <Group>
        <TableComponent.TableInput.SearchInput
          placeholder="Search by SN"
          value={sn}
          onChange={handleChange}
        />
        <TableComponent.TableInput.Button onClick={handleClick}>
          Search
        </TableComponent.TableInput.Button>
      </Group>
      <FilterButtonComponent />
    </Group>
  );
}
