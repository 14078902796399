import { Menu } from "@mantine/core";
import { DateTime } from "luxon";
import { useTableProvider } from "../../providers";
import { TableMenu } from "../TableMenu";

export function TableItems() {
  const { data } = useTableProvider();

  return (
    <>
      {data.map((b) => (
        <tr key={b.id}>
          <td>{b.id}</td>
          <td>{b.name}</td>
          <td>{b.createdAt.toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>
            <Menu>
              <TableMenu.Edit brand={b} />
              <TableMenu.Delete brand={b} />
            </Menu>
          </td>
        </tr>
      ))}
    </>
  );
}
