import { FC } from "react";
import { MediaQuery } from "@mantine/core";
import { Props } from "./types";

const MazutaDisplayQuery: FC<Props> = (props: Props) => {
  return (
    <MediaQuery
      {...(props.condition === "larger") 
        ? { largerThan: props.size }
        : { smallerThan: props.size }
      }
      styles={{ display: "none" }}
    >
      {props.children}
    </MediaQuery>
  );
}

export default MazutaDisplayQuery;