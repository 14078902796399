import { Button } from "@mantine/core";
import { usePickupRequest } from "hooks";
import { useAuth } from "providers/auth";
import { useState } from "react";
import { useParams } from "react-router";
import { net } from "utils/net";

export function OrderReceivedButtonComponent() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id } = useParams();
  const { pickupRequest, mutate } = usePickupRequest({ id });
  const { accessToken } = useAuth();

  async function handleClick() {
    try {
      setIsLoading(true);

      if (!pickupRequest) throw new Error("Pickup Request not found");
      if (!accessToken) throw new Error("Access Token not found");

      const relUrl = `/pickup-requests/${pickupRequest.id}/status`;
      const netInstance = net(accessToken);
      await netInstance.patch(relUrl, { status: "received" });

      await mutate();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  if (!pickupRequest) return <></>;
  if (pickupRequest.status !== "created") return <></>;
  return (
    <Button onClick={handleClick} loading={isLoading}>
      Mark as Order Received
    </Button>
  );
}
