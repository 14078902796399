import { Grid, Stack } from "@mantine/core";
import { MainContentComponent } from "components";
import { useParams } from "react-router";
import {
  DeviceDeliveryInformationSection,
  DeviceInformationSection,
  DeliveryInformationSection,
  SenderInformationSection,
  PaymentInformationSection,
  HeaderLeft,
  StatusStepperComponent,
} from "./components";
import { StepProvider } from "./providers";

export function DetailPage() {
  const { id } = useParams();

  return (
    <MainContentComponent headerLeft={<HeaderLeft />}>
      <Stack>
        <StepProvider pickupRequestId={id}>
          <StatusStepperComponent />
        </StepProvider>
        <Grid>
          <Grid.Col span={4}>
            <DeviceInformationSection />
          </Grid.Col>
          <Grid.Col span={4}>
            <Grid>
              <Grid.Col span={12}>
                <DeviceDeliveryInformationSection />
              </Grid.Col>
              <Grid.Col span={12}>
                <DeliveryInformationSection />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={4}>
            <Grid>
              <Grid.Col span={12}>
                <PaymentInformationSection />
              </Grid.Col>
              <Grid.Col span={12}>
                <SenderInformationSection />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Stack>
    </MainContentComponent>
  );
}
