import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme, _params, getRef) => ({
  box: {
    height: "100vh",
    backgroundImage: "url(assets/images/auth.png)",
    backgroundPosition: "center",
    backgroundSize: "cover"
  },
  boxColor: {
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  },
  loaderCenter: {
    height: "100vh"
  }
}));

export default useStyles;