import { PaginationType, TableContextProps, TableProviderProps } from "./types";
import { createContext } from "react";
import { funcNotImplemented, funcNotImplementedAsync } from "utils/misc";

import { useInvoices } from "hooks";
import { useContext, useState } from "react";

const TableContext = createContext<TableContextProps>({
  data: [],
  totalPage: 1,
  isLoading: false,
  refreshTable: funcNotImplementedAsync,
  changePage: funcNotImplemented,
});

export function TableProvider(props: TableProviderProps) {
  const { children } = props;

  const [page, setPage] = useState<PaginationType>({ limit: 5, offset: 0 });
  const { invoices, totalCount, itemPerPage, isLoading, mutate } = useInvoices({
    limit: page.limit,
    offset: page.offset,
  });

  function changePage(page: number) {
    setPage({
      limit: 5,
      offset: (page - 1) * 5,
    });
  }

  async function refreshTable() {
    await mutate();
  }

  return (
    <TableContext.Provider
      value={{
        data: invoices,
        isLoading: isLoading,
        totalPage: Math.ceil(totalCount / itemPerPage),
        refreshTable: refreshTable,
        changePage: changePage,
      }}
    >
      {children}
    </TableContext.Provider>
  );
}

export function useTableProvider() {
  return useContext(TableContext);
}
