import { DateTime } from "luxon";
import { Properties, Constructor } from "./types";

export interface OriginLocationModel extends Properties {}
export class OriginLocationModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    return new OriginLocationModel({
      id: res.id,
      locationLevel: res.location_level,
      code: res.code,
      name: res.name,
      provider: res.provider,
      createdAt: DateTime.fromISO(res.created_at),
      updatedAt: DateTime.fromISO(res.updated_at),
      deletedAt: res.deleted_at && DateTime.fromISO(res.deleted_at),
    });
  }
}
