import { Loader } from "@mantine/core";
import { Props } from "./types";

export function LoaderComponent(props: Props) {
  const { size, rootClassName, variant } = props;

  return (
    <Loader
      height={size}
      width={size}
      variant={variant ?? "oval"}
      {...(rootClassName ? { className: rootClassName } : {})}
    />
  );
}
