import { ReactSVG } from "react-svg";
import { TextInputComponent } from "./TextInput";
import { useStyles } from "./styles";
import { SearchInputComponentProps } from "./types/SearchInput";

export function SearchInputComponent(props: SearchInputComponentProps) {
  const { classes } = useStyles();

  return (
    <TextInputComponent
      {...props}
      placeholder={props.placeholder ?? "Search"}
      rightSection={
        <ReactSVG
          beforeInjection={(svg) => {
            svg.setAttribute("class", classes.svg);
          }}
          src="/assets/icons/search.svg"
        />
      }
    />
  );
}
