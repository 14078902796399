export function Box() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.0749 6.35981C17.0702 6.33784 17.0702 6.31512 17.0749 6.29315C17.0709 6.27391 17.0709 6.25405 17.0749 6.23481V6.15981L17.0249 6.03481C17.0046 6.0007 16.9793 5.96981 16.9499 5.94315L16.8749 5.87648H16.8333L13.5499 3.80148L10.4499 1.88481C10.3782 1.82792 10.2961 1.78547 10.2083 1.75981H10.1416C10.0671 1.74738 9.99109 1.74738 9.9166 1.75981H9.83327C9.73647 1.78122 9.64366 1.81778 9.55827 1.86815L3.33327 5.74315L3.25827 5.80148L3.18327 5.86815L3.09993 5.92648L3.05827 5.97648L3.00827 6.10148V6.17648V6.22648C3.00017 6.28174 3.00017 6.33788 3.00827 6.39315V13.6681C3.00798 13.8098 3.0438 13.9491 3.11233 14.0731C3.18086 14.197 3.27984 14.3014 3.39993 14.3765L9.64993 18.2431L9.77493 18.2931H9.8416C9.98259 18.3379 10.134 18.3379 10.2749 18.2931H10.3416L10.4666 18.2431L16.6666 14.4348C16.7867 14.3598 16.8857 14.2553 16.9542 14.1314C17.0227 14.0075 17.0586 13.8681 17.0583 13.7265V6.45148C17.0583 6.45148 17.0749 6.39315 17.0749 6.35981ZM9.99993 3.56815L11.4833 4.48481L6.82493 7.36815L5.33327 6.45148L9.99993 3.56815ZM9.1666 16.0681L4.58327 13.2681V7.94315L9.1666 10.7765V16.0681ZM9.99993 9.30981L8.40827 8.35148L13.0666 5.45981L14.6666 6.45148L9.99993 9.30981ZM15.4166 13.2431L10.8333 16.0931V10.7765L15.4166 7.94315V13.2431Z" />
    </svg>
  );
}
