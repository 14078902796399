import { FC } from "react";
import { Title } from "@mantine/core";
import { MainContentComponent } from "components";

const DashboardPage: FC = () => {
  return (
    <MainContentComponent
      headerLeft={<Title>Dashboard</Title>}
    ></MainContentComponent>
  );
};

export default DashboardPage;
