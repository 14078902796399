import { useAuth } from "providers/auth";
import NotificationSystemComponent from "components/NotificationSystem";
import { NotificationSystemParams } from "./types";

const useDefaultSystem = () => {
  const useAccessToken = () => {
    const { accessToken } = useAuth();
    return accessToken;
  }

  const useHeaderAuthConfig = () => {
    const { accessToken } = useAuth();
    return accessToken
      ? { headers: { Authorization: `Bearer ${accessToken}` } }
      : undefined;
  }

  const notificationSystem = (params: NotificationSystemParams) => {
    const { isSuccess, message, onClose, onOpen } = params;

    NotificationSystemComponent({
      data: { type: "show" },
      color: (isSuccess) ? "green" : "orange",
      title: (isSuccess) ? "Success" : "Error",
      message,
      ...((onClose) ? { onClose } : {}),
      ...((onOpen) ? { onOpen } : {})
    });
  }

  return { useAccessToken, useHeaderAuthConfig, notificationSystem };
}

export default useDefaultSystem;