import { SelectComponent } from "components";
import { useDataProvider } from "../../../../providers";

export function InvoiceTypeComponent() {
  const { dispatch, data } = useDataProvider();

  function handleChange(value: string) {
    dispatch({
      type: "SET_DATA",
      key: "sourceType",
      value: value,
    });
  }

  return (
    <SelectComponent
      label="Invoice Type"
      data={[
        { value: "ad-hoc", label: "Ad Hoc" },
        { value: "service-center", label: "Service Center" },
        { value: "pickup-request", label: "Pickup Request" },
      ]}
      value={data.sourceType}
      onChange={handleChange}
    />
  );
}
