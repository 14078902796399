import { DateTime } from "luxon";
import { BrandModel, WarrantyModel } from "models";
import { Constructor, Properties } from "./types";

export interface DeviceModel extends Properties {}
export class DeviceModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    return new DeviceModel({
      id: res.id,
      name: res.name,
      brand: res.brand && BrandModel.fromResponse(res.brand),
      warranty: res.warranty.map((w: any) => WarrantyModel.fromResponse(w)),
      createdAt: DateTime.fromISO(res.created_at),
      updatedAt: DateTime.fromISO(res.updated_at),
      deletedAt: res.deleted_at && DateTime.fromISO(res.deleted_at),
    });
  }
}
