import { Group } from "@mantine/core";
import { ButtonComponent } from "components/Button";
import { CancelButtonComponent } from "./CancelButton";
import { ActionButtonsComponentProps } from "./types";

export function ActionButtonsComponent(props: ActionButtonsComponentProps) {
  const { cancel, submit, context, id } = props;

  return (
    <Group position="right">
      <CancelButtonComponent
        context={context}
        id={id}
        disabled={cancel?.disabled}
      />
      <ButtonComponent {...submit} type="submit">
        {submit.label}
      </ButtonComponent>
    </Group>
  );
}
