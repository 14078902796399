import { Group, Stack, Text } from "@mantine/core";
import { ButtonComponent, InputLabelComponent } from "components";
import { FileInputComponentProps } from "./types";
import React, { useEffect, useRef, useState } from "react";

export function FileInputComponent(props: FileInputComponentProps) {
  const { label, acceptMime, onChange } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File>();

  function handleSelectClick() {
    if (!inputRef.current) return;
    inputRef.current.click();
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return;
    setSelectedFile(e.target.files[0]);
  }

  function getFileName(file?: File) {
    if (!file) return "No File Selected";
    return file.name;
  }

  useEffect(() => {
    if (onChange) onChange(selectedFile);
  }, [selectedFile, onChange]);

  return (
    <Stack>
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        accept={acceptMime}
        onChange={handleFileChange}
      />
      <InputLabelComponent>{label}</InputLabelComponent>
      <Group>
        <Text>{getFileName(selectedFile)}</Text>
        <ButtonComponent
          onClick={handleSelectClick}
          mainStyle="small"
          variant="outline"
        >
          Select File
        </ButtonComponent>
      </Group>
    </Stack>
  );
}
