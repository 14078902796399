import {
  CourierComponent,
  DeviceNameComponent,
  PaymentStatusComponent,
  ComplaintComponent,
  ServiceCenterComponent,
} from "./components";

export function TableItemComponent() {
  return <></>;
}

TableItemComponent.DeviceName = DeviceNameComponent;
TableItemComponent.Courier = CourierComponent;
TableItemComponent.PaymentStatus = PaymentStatusComponent;
TableItemComponent.Complaint = ComplaintComponent;
TableItemComponent.ServiceCenter = ServiceCenterComponent;
