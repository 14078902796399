import { BrandModel } from "models";
import { SelectComponent } from "components";

import { useBrands } from "hooks";
import { useDataProvider } from "../../providers";

export function BrandInputComponent() {
  const { brands } = useBrands({ limit: 9999 });
  const { data, dispatch } = useDataProvider();

  function toData(brands: BrandModel[]) {
    return brands.map((brand) => ({
      label: brand.name,
      value: brand.id,
    }));
  }

  function handleChange(value: string) {
    dispatch({
      type: "SET_DATA",
      key: "brandId",
      value,
    });
  }

  return (
    <SelectComponent
      label="Brand"
      data={toData(brands)}
      value={data.brandId}
      onChange={handleChange}
      required
    />
  );
}
