import useSWR from "swr";
import { UseSerialNumberProps } from "./types";
import { fetcher } from "utils/net";
import { useAuth } from "providers/auth";
import { useEffect, useState } from "react";
import { SerialNumberModel } from "models";

export function useSerialNumber(props: UseSerialNumberProps) {
  const { serialNumber, serialNumberId } = props;
  const [mSerialNumber, setSerialNumebr] = useState<SerialNumberModel>();

  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!accessToken) return undefined;

    if (serialNumber) {
      const relativeUrl = `/serial-numbers/find?serial_number=${serialNumber}`;
      return [relativeUrl, accessToken];
    } else if (serialNumberId) {
      const relUrl = `/serial-numbers/${serialNumberId}`;
      return [relUrl, accessToken];
    }
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setSerialNumebr(undefined);
      return;
    }

    const mSerialNumber = SerialNumberModel.fromResponse(data);
    setSerialNumebr(mSerialNumber);
  }, [data]);

  return {
    serialNumber: mSerialNumber,
    isLoading: isLoading,
    error: error,
  };
}
