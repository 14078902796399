import { useCallback } from "react";
import { InvoiceSourceComponentProps } from "./types";

export function InvoiceSourceComponent(props: InvoiceSourceComponentProps) {
  const getLabel = useCallback(() => {
    if (props.invoice.source?.type === undefined) {
      return "";
    } else if (props.invoice.source?.type === "pickup-request") {
      return "Pickup Request";
    } else if (props.invoice.source?.type === "ad-hoc") {
      return "Ad Hoc";
    } else if (props.invoice.source?.type === "service-center") {
      return "Service Center";
    }
  }, [props.invoice.source?.type]);

  if (!props.invoice.source) return <></>;
  return <span>{getLabel()}</span>;
}
