import lodash from "lodash";
import { SparePartModel } from "models";
import { fetcher } from "utils/net";

import useSWR from "swr";
import { useAuth } from "providers/auth";
import { useEffect, useState } from "react";
import { UseSparePartProps } from "./types";

export function useSpareParts(props?: UseSparePartProps) {
  const limit = lodash(props).get("limit", 5);
  const offset = lodash(props).get("offset", 0);

  const [spareParts, setSpareParts] = useState<SparePartModel[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [itemPerPage, setItemPerPage] = useState<number>(0);

  const { accessToken } = useAuth();
  const { data, error, mutate } = useSWR(() => {
    if (!accessToken) return undefined;

    return [`/parts?limit=${limit}&offset=${offset}`, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) return;

    const sps = data.data.map((d: any) => SparePartModel.fromResponse(d));
    setSpareParts(sps);

    // We need to set the totalCount
    // And itemPerPage based on the data.pagination.totalCount and data.pagination.limit
    if (!data.pagination) return;
    setTotalCount(data.pagination.total_count);
    setItemPerPage(data.pagination.limit);
  }, [data]);

  return {
    spareParts: spareParts,
    isLoading: !error && spareParts.length === 0,
    error: error,
    totalCount: totalCount,
    itemPerPage: itemPerPage,
    mutate: mutate,
  };
}
