import { Menu } from "@mantine/core";
import { DateTime } from "luxon";
import { MenuItem, TableItemComponent } from "..";
import { useTableProvider } from "../../providers";

export function TableItemsComponent() {
  const { data } = useTableProvider();

  return (
    <>
      {data.map((invoice) => (
        <tr key={invoice.id}>
          <td>{invoice.id}</td>
          <td>
            <TableItemComponent.SerialNumber invoice={invoice} />
          </td>
          <td>
            <TableItemComponent.InvoiceSource invoice={invoice} />
          </td>
          <td>
            <TableItemComponent.Currency value={invoice.total} />
          </td>
          <td>
            <TableItemComponent.PaymentStatus invoice={invoice} />
          </td>
          <td>{invoice.createdAt.toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>
            <Menu>
              <MenuItem.PaymentLink invoice={invoice} />
              <MenuItem.ViewDetail invoice={invoice} />
            </Menu>
          </td>
        </tr>
      ))}
    </>
  );
}
