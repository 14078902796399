import { Card, Stack } from "@mantine/core";
import { CardDataDisplayComponent } from "components";
import { usePickupRequest } from "hooks";
import { useParams } from "react-router";
import { SectionTitleComponent } from "..";

export function DeliveryInformationSection() {
  const { id } = useParams();
  const { pickupRequest } = usePickupRequest({ id });

  return (
    <Card>
      <Stack>
        <SectionTitleComponent>Delivery Information</SectionTitleComponent>
        <CardDataDisplayComponent
          label="Courier"
          value={pickupRequest?.courierInformation.courier.name}
        />
        <CardDataDisplayComponent
          label="Service"
          value={pickupRequest?.courierInformation.courierService.name}
        />
        <CardDataDisplayComponent
          label="Destination"
          value={pickupRequest?.serviceCenter.name}
        />
      </Stack>
    </Card>
  );
}
