import { DispatchAction, FormData } from "./types";

export const reducer = (state: FormData, action: DispatchAction) => {
  switch (action.type) {
    case "SET_DATA":
      return Object.assign({}, state, { [action.key]: action.value });
    case "ADD_WARRANTY":
      // We need to check if partId already exists in the state.warranty
      // If it does, we don't want to add it again
      // If it doesn't, we want to add it)
      const { warranty } = state;
      const partIdExists = warranty?.find((w) => w.part.id === action.part.id);
      if (partIdExists) return state;

      return Object.assign({}, state, {
        warranty: [
          ...(state.warranty || []),
          { part: action.part, warrantyPeriod: action.period },
        ],
      });
    case "REMOVE_WARRANTY":
      return Object.assign({}, state, {
        warranty: state.warranty?.filter((w) => w.part.id !== action.part.id),
      });
    case "UPDATE_WARRANTY":
      const { warranty: w } = state;
      return Object.assign({}, state, {
        warranty: w?.map((w) =>
          w.part.id === action.part.id
            ? { ...w, warrantyPeriod: action.value }
            : { ...w }
        ),
      });
    default:
      throw new Error("Not Implemented");
  }
};
