import validator from "validator";
import { DataContextProps, DataProviderProps, FormData } from "./types";
import { createContext } from "react";
import { reducer } from "./reducer";
import { funcNotImplemented } from "utils/misc";

import { useState, useEffect, useContext, useReducer } from "react";
import { DeviceModel } from "models";

const initialData: FormData = {
  name: "",
  brandId: "",
  warranty: [],
};

const DataContext = createContext<DataContextProps>({
  data: initialData,
  isClean: false,
  dispatch: funcNotImplemented,
});

const init = (device: DeviceModel) => {
  return {
    name: device.name,
    brandId: device.brand.id,
    warranty: device.warranty,
  };
};

export function DataProvider(props: DataProviderProps) {
  const { children, device } = props;
  const [data, dispatch] = useReducer(reducer, device, init);
  const [isClean, setIsClean] = useState<boolean>(false);

  useEffect(() => {
    setIsClean(
      !validator.isEmpty(data.name ?? "") &&
        !validator.isEmpty(data.brandId ?? "")
    );
  }, [data]);

  return (
    <DataContext.Provider
      value={{
        data,
        isClean,
        dispatch,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export function useDataProvider() {
  return useContext(DataContext);
}
