export function PriceTagAlt() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.83318 4.09336C4.66837 4.09336 4.50725 4.14223 4.37021 4.2338C4.23317 4.32537 4.12636 4.45552 4.06328 4.60779C4.00021 4.76006 3.98371 4.92761 4.01586 5.08927C4.04802 5.25092 4.12739 5.3994 4.24393 5.51595C4.36047 5.63249 4.50896 5.71186 4.67061 5.74401C4.83226 5.77617 4.99982 5.75966 5.15209 5.69659C5.30436 5.63352 5.43451 5.52671 5.52608 5.38967C5.61764 5.25262 5.66652 5.09151 5.66652 4.92669C5.66652 4.70568 5.57872 4.49372 5.42244 4.33743C5.26616 4.18115 5.0542 4.09336 4.83318 4.09336ZM17.0915 8.91002L9.19152 1.02669C9.11382 0.942909 9.01972 0.876003 8.91507 0.830128C8.81042 0.784254 8.69745 0.760391 8.58318 0.760024H3.58318C3.47351 0.75939 3.36479 0.78041 3.26326 0.821881C3.16173 0.863351 3.06938 0.924456 2.99152 1.00169L0.908184 3.07669C0.83095 3.15456 0.769846 3.2469 0.728375 3.34843C0.686905 3.44997 0.665884 3.55868 0.666518 3.66836V8.66836C0.669587 8.889 0.755906 9.10033 0.908184 9.26002L8.81652 17.1767C8.97621 17.329 9.18755 17.4153 9.40818 17.4184C9.51786 17.419 9.62658 17.398 9.72811 17.3565C9.82964 17.315 9.92198 17.2539 9.99985 17.1767L17.0915 10.085C17.1688 10.0072 17.2299 9.91481 17.2713 9.81328C17.3128 9.71175 17.3338 9.60303 17.3332 9.49336C17.328 9.27561 17.2419 9.06761 17.0915 8.91002ZM9.40818 15.4184L2.33318 8.33502V4.01002L3.91652 2.42669H8.23318L15.3165 9.50169L9.40818 15.4184Z"
        fill="#ABABAB"
      />
    </svg>
  );
}
