import { FC } from "react";
import { PasswordInput } from "@mantine/core";
import useStyles from "./styles";
import { Props } from "./types";

const MazutaPasswordInput: FC<Props> = ({ props, ...other }: Props) => {
  const { classes, cx } = useStyles({
    mainStyle: props.mainStyle ?? "normal"
  });

  return (
    <PasswordInput
      {...other}
      classNames={{
        innerInput: cx(classes.innerInput, props.innerInputStyle),
        input: cx(classes.input, props.inputStyle),
        label: cx(classes.label, props.labelStyle)
      }}
      label={props.label}
      placeholder={props.placeholder}
      radius={props.radius ?? 12}
      {...(props.condition)
        ? ((props.condition === "required")
          ? { required: true }
          : { disabled: true }
        ): {}
      }
    />
  );
}

export default MazutaPasswordInput;