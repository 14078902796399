import { Box } from "./Box";
import { Dashboard } from "./Dashboard";
import { DesktopCloud } from "./DesktopCloud";
import { Logo } from "./Logo";
import { PriceTagAlt } from "./PriceTagAlt";
import { SignOutAlt } from "./SignOutAlt";
import { Transaction } from "./Transaction";

export function VectorImages() {
  return <></>;
}

VectorImages.Logo = Logo;
VectorImages.Box = Box;
VectorImages.Dashboard = Dashboard;
VectorImages.DesktopCloud = DesktopCloud;
VectorImages.PriceTagAlt = PriceTagAlt;
VectorImages.SignOutAlt = SignOutAlt;
VectorImages.Transaction = Transaction;
