import { SparePartModel } from "models";
import { Properties, Constructor } from "./types";

export interface ExtendedWarrantyModel extends Properties {}
export class ExtendedWarrantyModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    return new ExtendedWarrantyModel({
      partId: res.part_id,
      part: res.part && SparePartModel.fromResponse(res.part),
      extendFor: res.extend_for,
    });
  }
}
