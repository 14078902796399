import { Badge } from "@mantine/core";
import { OutDirectionComponentProps } from "./types";

export function OutDirectionComponent(props: OutDirectionComponentProps) {
  const { directions } = props;

  if (directions.includes("out")) {
    return <Badge color="green">OUT</Badge>;
  } else return <></>;
}
