import { SparePartDeleteModalProps } from "./types";
import { loadingComplete, showLoading } from "utils/loader";
import { net } from "utils/net";

import { useAuth } from "providers/auth";
import { useState } from "react";
import { Box, Stack, Text } from "@mantine/core";
import { ModalComponent } from "components";

export function SparePartDeleteModal(props: SparePartDeleteModalProps) {
  const { context, id, innerProps } = props;
  const { part, onSubmit } = innerProps;
  const { accessToken } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!accessToken) return;

    try {
      showLoading("spare-part.delete");
      setIsLoading(true);
      const relativeUrl = `/parts/${part.id}`;
      const netInstance = net(accessToken);
      await netInstance.delete(relativeUrl);
      if (onSubmit) await onSubmit();

      context.closeModal(id);
      loadingComplete("spare-part.delete", false);
    } catch (err) {
      loadingComplete("spare-part.delete", true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        <Box>
          <Text>
            Your <strong>{part.name}</strong> spare part will be deleted. Please
            note that you can not recover the deleted spare part.
          </Text>
        </Box>
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          submit={{ label: "Delete", disabled: isLoading }}
        />
      </Stack>
    </form>
  );
}
