import { ButtonComponent } from "components/Button";
import { CancelButtonComponentProps } from "./types";

export function CancelButtonComponent(props: CancelButtonComponentProps) {
  const { id, context } = props;

  function handleClick() {
    context.closeModal(id);
  }

  return (
    <ButtonComponent {...props} variant="outline" onClick={handleClick}>
      Cancel
    </ButtonComponent>
  );
}
