import { ButtonComponent } from "components";
import { loadingComplete, showLoading } from "utils/loader";
import { net } from "utils/net";

import { useAuth } from "providers/auth";
import { useState } from "react";
import { useDataProvider } from "../../providers";
import { useNavigate } from "react-router-dom";

export function AddNewButtonComponent() {
  const navigate = useNavigate();
  const { data, isClean } = useDataProvider();
  const { accessToken } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleClick() {
    if (!isClean) return;
    if (!accessToken) return;

    try {
      showLoading("device.add-new");
      setIsLoading(true);

      const relUrl = `/devices`;
      const body = {
        name: data.name,
        brand_id: data.brandId,
        warranty: data.warranty?.map((w) => ({
          part_id: w.part.id,
          warranty_period: w.period,
        })),
      };

      const netInstance = net(accessToken);
      await netInstance.post(relUrl, body);
      loadingComplete("device.add-new", false);

      navigate(-1);
    } catch (err) {
      loadingComplete("device.add-new", true);
    }
  }

  return (
    <ButtonComponent disabled={!isClean || isLoading} onClick={handleClick}>
      Add New
    </ButtonComponent>
  );
}
