import { Grid, Group, Stepper } from "@mantine/core";
import { StatusStepperForm } from "..";
import { useStepProvider } from "../../providers";

export function StatusStepperComponent() {
  const { currentStep } = useStepProvider();

  return (
    <Grid>
      <Grid.Col span={6}>
        <Stepper active={currentStep}>
          <Stepper.Step
            label="Create Order"
            description="Courier order is needed"
          ></Stepper.Step>
          <Stepper.Step
            label="Receive"
            description="Double check the machine"
          ></Stepper.Step>
        </Stepper>
      </Grid.Col>
      <Grid.Col span={6}>
        <Group position="right">
          <StatusStepperForm.OrderCreatedButton />
          <StatusStepperForm.OrderReceivedButton />
          <StatusStepperForm.CancelButton />
        </Group>
      </Grid.Col>
    </Grid>
  );
}
