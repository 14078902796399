import DashboardPage from "pages/DashboardPage";
import {
  BrandPage,
  SparePartPage,
  DevicePage,
  SerialNumberPage,
  WarehouseTransactionsPage,
  SaleTransactionPage,
  PickupRequestPage,
  InvoicePage,
} from "pages";

const PrivateRoutes = () => {
  return [
    { path: "devices/:id/edit", element: <DevicePage.Edit /> },
    { path: "pickup-requests/:id", element: <PickupRequestPage.Detail /> },
    { path: "devices/add-new", element: <DevicePage.AddNew /> },
    { path: "invoices/add-new", element: <InvoicePage.AddNew /> },
    { path: "product-serial-numbers", element: <SerialNumberPage /> },
    { path: "dashboard", element: <DashboardPage /> },
    { path: "brands", element: <BrandPage /> },
    { path: "spare-parts", element: <SparePartPage /> },
    { path: "devices", element: <DevicePage.List /> },
    { path: "pickup-requests", element: <PickupRequestPage.List /> },
    { path: "warehouse-transactions", element: <WarehouseTransactionsPage /> },
    { path: "sale-transactions", element: <SaleTransactionPage /> },
    { path: "invoices", element: <InvoicePage.List /> },
  ];
};

export { PrivateRoutes };
