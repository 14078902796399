import { createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme, params, getRef) => ({
  input: {
    backgroundColor: "transparent",
    border: "2px solid #A6A6A6",
    color: "#212121",
    fontSize: 18,
    fontWeight: 400,
    height: 59,
    lineHeight: "27px",
    padding: "16px 24px",
  },
  label: {
    color: "#212121",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "27px",
  },
}));
