import { Button } from "@mantine/core";
import { usePickupRequest } from "hooks";
import { useAuth } from "providers/auth";
import { useState } from "react";
import { useParams } from "react-router";
import { net } from "utils/net";
import { useStepProvider } from "../../../../providers";
import { StatusMapping } from "./types";

export function CancelButtonComponent() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { currentStep } = useStepProvider();
  const { id } = useParams();
  const { pickupRequest, mutate } = usePickupRequest({ id });
  const { accessToken } = useAuth();

  function ableToPrev() {
    return currentStep > 0;
  }

  async function handleClick() {
    try {
      setIsLoading(true);
      if (!ableToPrev()) throw new Error("No pickup request");
      if (!pickupRequest) throw new Error("No pickup request");
      if (!accessToken) throw new Error("No access token");

      const statusMapping: StatusMapping = {
        pending: { prev: undefined },
        created: { prev: "pending" },
        received: { prev: "created" },
      };

      const prevStatus = statusMapping[pickupRequest.status].prev;
      if (!prevStatus) throw new Error("No previous status");

      const relUrl = `/pickup-requests/${id}/status`;
      const netInstance = net(accessToken);
      await netInstance.patch(relUrl, { status: prevStatus });
      await mutate();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  if (!pickupRequest) return <></>;
  return (
    <Button
      variant="outline"
      onClick={handleClick}
      disabled={!ableToPrev()}
      loading={isLoading}
    >
      Cancel Step
    </Button>
  );
}
