import CurrencyUtils from "utils/currency";
import { TextInputComponent } from "components";

import { useState, useEffect } from "react";
import { useDataProvider } from "../../../../providers";

export function TotalComponent() {
  const { data } = useDataProvider();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(data.items.reduce((acc, i) => acc + i.qty * i.price, 0));
  }, [data.items]);

  return (
    <TextInputComponent
      label="Total"
      value={CurrencyUtils.toCurrency(total)}
      disabled
    />
  );
}
