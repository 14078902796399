import { useTableProvider } from "../../providers";

import { MainContentComponent, TableComponent } from "components";
import {
  HeaderLeft,
  TableHeadComponent,
  HeaderRight,
  TableItemsComponent,
  TablePaginationComponent,
} from "..";

export function MainComponent() {
  const { isLoading } = useTableProvider();

  return (
    <MainContentComponent
      headerLeft={<HeaderLeft />}
      headerRight={<HeaderRight />}
    >
      <TableComponent>
        <TableComponent.Head>
          <TableHeadComponent />
        </TableComponent.Head>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Device</th>
            <th>Transaction Date</th>
            <th>Files</th>
            <th>Last Update</th>
          </tr>
        </thead>
        <TableComponent.Body colCount={6} isLoading={isLoading}>
          <TableItemsComponent />
        </TableComponent.Body>
        <TableComponent.Pagination>
          <TablePaginationComponent />
        </TableComponent.Pagination>
      </TableComponent>
    </MainContentComponent>
  );
}
