import { Menu } from "@mantine/core";
import { ViewDetailMenuItemProps } from "./types";
import { useNavigate } from "react-router-dom";

export function ViewDetailMenuItem(props: ViewDetailMenuItemProps) {
  const { invoice } = props;
  const navigate = useNavigate();

  function handleClick() {
    navigate(`/invoices/${invoice.id}`);
  }

  return <Menu.Item onClick={handleClick}>View Detail</Menu.Item>;
}
