import { TextInputComponent } from "components";
import { useDataProvider } from "../../../../providers";

export function CustomerNameComponent() {
  const { dispatch, data } = useDataProvider();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: "UPDATE_CUSTOMER_INFORMATION",
      value: {
        ...data.customerInformation,
        name: e.currentTarget.value,
      },
    });
  }

  return (
    <TextInputComponent
      label="Customer Name"
      onChange={handleChange}
      value={data.customerInformation?.name ?? ""}
    />
  );
}
