import { Button, Divider, Menu } from "@mantine/core";
import { ChevronDown } from "tabler-icons-react";
import {
  AddNewSerialNumberMenuItemComponent,
  ExportCsvMenuItemComponent,
  ImportCsvMenuItemComponent,
} from "..";

export function HeaderRight() {
  return (
    <Menu
      control={
        <Button rightIcon={<ChevronDown />} uppercase>
          Menu
        </Button>
      }
    >
      <AddNewSerialNumberMenuItemComponent />
      <Divider />
      <ImportCsvMenuItemComponent />
      <ExportCsvMenuItemComponent />
    </Menu>
  );
}
