import { Button } from "@mantine/core";
import { Props } from "./types";
import { LoaderComponent } from "components";
import { useStyles } from "./styles";

export function ButtonComponent(props: Props) {
  const {
    type,
    rootStyle,
    mainStyle,
    height,
    labelStyle,
    isFetching,
    leftIcon,
    color,
    disabled,
    radius,
    variant,
    loaderSize,
    children,
  } = props;

  const { classes, cx } = useStyles({
    mainStyle: mainStyle ?? "large",
    height: height,
  });

  return (
    <Button
      type={type ?? "button"}
      radius={radius ?? 8}
      classNames={{
        root: cx(classes.root, rootStyle),
        label: cx(classes.label, labelStyle),
      }}
      leftIcon={
        isFetching ? <LoaderComponent size={loaderSize ?? 13} /> : leftIcon
      }
      {...(color ? { color: color } : {})}
      {...(disabled ? { disabled: true } : {})}
      {...(variant ? { variant: variant } : {})}
      {...(props.onClick ? { onClick: props.onClick } : {})}
    >
      {children}
    </Button>
  );
}
