import validator from "validator";
import { Alert, Text } from "@mantine/core";
import { ErrorAlertComponentProps } from "./types";
import { AlertCircle } from "tabler-icons-react";

export function ErrorAlertComponent(props: ErrorAlertComponentProps) {
  const { errorMessage } = props;

  if (!errorMessage) return <></>;
  if (validator.isEmpty(errorMessage)) return <></>;
  return (
    <Alert icon={<AlertCircle size={16} />} title="Invalid" color="red">
      <Text>{errorMessage}</Text>
    </Alert>
  );
}
