import { showNotification, updateNotification } from "@mantine/notifications";
import { Props } from "./types";

const MazutaNotificationSystem = ({ data, ...props }: Props) => {
  const config = {
    ...props,
    styles: () => ({
      title: {
        lineHeight: "27px",
        fontSize: 18,
        fontWeight: 700
      },
      description: {
        fontSize: 16
      }
    })
  };

  if(data.type === "show") {
    const showConfig = {
      ...config,
      ...(data.id ? { id: data.id } : {})
    };
    return showNotification(showConfig);
  }
  else {
    const updateConfig = {
      ...config,
      id: data.id
    };
    return updateNotification(updateConfig);
  }
}

export default MazutaNotificationSystem;