import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme, _params, getRef) => ({
  buttonHeader: {
    width: 180
  },
  header: {
    textAlign: "center"
  }
}));

export default useStyles;