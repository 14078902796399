import { SelectComponent } from "components/Select";
import { SparePartModel } from "models";
import { SparePartSelectInputProps } from "./types/SparePart";
import { useSpareParts } from "hooks";

export function SparePartSelectInput(props: SparePartSelectInputProps) {
  const { value, onChange } = props;
  const { spareParts } = useSpareParts({ limit: 99999 });

  function toData(sp: SparePartModel[]) {
    return sp.map((s) => ({
      label: s.name,
      value: s.id,
    }));
  }

  function handleChange(partId: string) {
    const part = spareParts.find((s) => s.id === partId);
    if (onChange && part) onChange(part);
  }

  return (
    <SelectComponent
      label="Spare Part"
      data={toData(spareParts)}
      onChange={handleChange}
      value={value?.id}
    />
  );
}
