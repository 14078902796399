import React from "react";
import { Box, Space, Stack, Text } from "@mantine/core";
import { TemplateDownloadSectionComponent } from "./components";
import { FileInputComponent, ModalComponent } from "components";
import { WarehouseTransactionImportCsvModalProps } from "./types";
import { loadingComplete, showLoading } from "utils/loader";
import { net, responseChecker } from "utils/net";

import { useEffect, useState } from "react";
import { useAuth } from "providers/auth";

export function WarehouseTransactionImportCsvModal(
  props: WarehouseTransactionImportCsvModalProps
) {
  const { context, id, innerProps } = props;
  const { onSubmit } = innerProps;

  const [selectedFile, setSelectedFile] = useState<File>();
  const [isClean, setIsClean] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { accessToken } = useAuth();

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!accessToken) return;
    if (!isClean) return;
    if (!selectedFile) return;

    try {
      showLoading("warehouse-transaction.import-csv");
      setIsLoading(true);

      const netInstance = net(accessToken, {
        "Content-Type": "multipart/form-data",
      });

      const relUrl = `/warehouse-transactions/import-data/csv`;
      const formData = new FormData();

      formData.append("file", selectedFile);
      const res = await netInstance.post(relUrl, formData);
      responseChecker(res);

      if (onSubmit) onSubmit();
      context.closeModal(id);
      loadingComplete("warehouse-transaction.import-csv", false);
    } catch (err) {
      loadingComplete("warehouse-transaction.import-csv", true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsClean(selectedFile !== undefined && selectedFile !== null);
  }, [selectedFile]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <TemplateDownloadSectionComponent action="create" />
        <Text>Lastly, please upload the template here.</Text>
        <FileInputComponent
          label="CSV File"
          acceptMime="text/csv"
          onChange={setSelectedFile}
        />
        <Space />
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          cancel={{ disabled: isLoading }}
          submit={{ label: "Import CSV", disabled: !isClean || isLoading }}
        />
      </Stack>
    </Box>
  );
}
