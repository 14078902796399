import lodash from "lodash";
import { PickupRequestModel } from "models";
import { UsePickupRequestProps } from "./types";
import { fetcher } from "utils/net";

import useSWR from "swr";
import { useAuth } from "providers/auth";
import { useEffect, useState } from "react";

export function usePickupRequests(props?: UsePickupRequestProps) {
  const limit = lodash(props).get("limit", 5);
  const offset = lodash(props).get("offset", 0);
  const { filter } = props ?? {};

  const [totalCount, setTotalCount] = useState<number>(0);
  const [itemPerPage, setItemPerPage] = useState<number>(0);
  const [pickupRequests, setPickupRequests] = useState<PickupRequestModel[]>(
    []
  );

  const { accessToken } = useAuth();
  const { data, error, isLoading, mutate } = useSWR(() => {
    if (!accessToken) return undefined;

    const baseUrl = `/pickup-requests?limit=${limit}&offset=${offset}`;
    if (filter) {
      // Create filter object that needs to be appended to URL SearchPaarams
      const xFilter = lodash({ request_status: filter.requestStatus })
        .omitBy(lodash.isNil)
        .value();

      if (Object.keys(xFilter).length === 0) return [baseUrl, accessToken];

      // Now create the params based on the xFilter object
      // Since the xFilter is object, we need to convert it to URLSearchParams
      const params = new URLSearchParams();
      Object.keys(xFilter).forEach((key) => {
        const value = lodash(xFilter).get(key);
        params.append(key, value);
      });

      return [`${baseUrl}&${params.toString()}`, accessToken];
    } else return [baseUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) return;

    const pr = data.data.map((d: any) => PickupRequestModel.fromResponse(d));
    setPickupRequests(pr);

    // We need to set the totalCount
    // And itemPerPage based on the data.pagination.totalCount and data.pagination.limit
    if (!data.pagination) return;
    setTotalCount(data.pagination.total_count);
    setItemPerPage(data.pagination.limit);
  }, [data]);

  return {
    pickupRequests: pickupRequests,
    isLoading: isLoading,
    error: error,
    totalCount: totalCount,
    itemPerPage: itemPerPage,
    mutate: mutate,
  };
}
