import CurrencyUtils from "utils/currency";
import { Card, Stack } from "@mantine/core";
import { CardDataDisplayComponent } from "components";
import { usePickupRequest } from "hooks";
import { useCallback } from "react";
import { useParams } from "react-router";
import { PaymentInformationForm, SectionTitleComponent } from "..";

export function PaymentInformationSection() {
  const { id } = useParams();
  const { pickupRequest } = usePickupRequest({ id });

  const getTotal = useCallback(() => {
    if (!pickupRequest) return 0;
    const deliveryFee = pickupRequest.deliveryFeeInformation.deliveryFee;
    const insuranceFee = pickupRequest.deliveryFeeInformation.insuranceFee;
    return deliveryFee + insuranceFee;
  }, [pickupRequest]);

  return (
    <Card>
      <Stack>
        <SectionTitleComponent>Payment Information</SectionTitleComponent>
        <CardDataDisplayComponent
          label="Delivery Fee"
          value={CurrencyUtils.toCurrency(
            pickupRequest?.deliveryFeeInformation.deliveryFee ?? 0
          )}
        />
        <CardDataDisplayComponent
          label="Insurance Fee"
          value={CurrencyUtils.toCurrency(
            pickupRequest?.deliveryFeeInformation.insuranceFee ?? 0
          )}
        />
        <CardDataDisplayComponent
          label="Total Fee"
          value={CurrencyUtils.toCurrency(getTotal())}
        />
        <PaymentInformationForm.PaymentStatus pikcupRequest={pickupRequest} />
      </Stack>
    </Card>
  );
}
