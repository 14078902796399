import { FC } from "react";
import { AuthProvider } from "./providers/auth";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";

import NotFoundPage from "pages/NotFoundPage";
import LoginPage from "pages/LoginPage";
import { LogoutPage } from "pages";
import { PrivateRoutes } from "routes";
import { ModalsProvider } from "@mantine/modals";
import { PrivateRouteComponent } from "components";

import {
  CreateSparePartModal,
  SparePartDeleteModal,
  SparePartEditModal,
  CreateBrandModal,
  BrandEditModal,
  BrandDeleteModal,
  SelectWarrantyModal,
  DeviceDeleteModal,
  CreateSerialNumberModal,
  EditSerialNumberModal,
  DeleteSerialNumberModal,
  ImportSerialNumberCsvModal,
  ExportSerialNumberModal,
  CreateWarehouseTransactionModal,
  WarehouseTransactionImportCsvModal,
  CreatePickupRequestModal,
  ViewPaymentLinkModal,
  InvoiceAddItemModal,
  MergeDevicesModal,
} from "modals";

const MyRoute = () => {
  return useRoutes([
    {
      path: "",
      element: <Navigate to="login" />,
    },
    {
      path: "oops",
      element: <NotFoundPage />,
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "logout",
      element: <LogoutPage />,
    },
    {
      path: "/",
      element: <PrivateRouteComponent />,
      children: PrivateRoutes(),
    },
    {
      path: "*",
      element: <Navigate to="oops" replace={true} />,
    },
  ]);
};

const App: FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ModalsProvider
          modals={{
            "spare-part.create": CreateSparePartModal,
            "spare-part.edit": SparePartEditModal,
            "spare-part.delete": SparePartDeleteModal,
            "brand.create": CreateBrandModal,
            "brand.edit": BrandEditModal,
            "brand.delete": BrandDeleteModal,
            "warranty.select": SelectWarrantyModal,
            "device.delete": DeviceDeleteModal,
            "device.merge": MergeDevicesModal,
            "serial-number.create": CreateSerialNumberModal,
            "serial-number.edit": EditSerialNumberModal,
            "serial-number.delete": DeleteSerialNumberModal,
            "serial-number.import-csv": ImportSerialNumberCsvModal,
            "serial-number.export-csv": ExportSerialNumberModal,
            "warehouse-transaction.create": CreateWarehouseTransactionModal,
            "warehouse-transaction.import-csv":
              WarehouseTransactionImportCsvModal,
            "pickup-request.create": CreatePickupRequestModal,
            "payment-link.view": ViewPaymentLinkModal,
            "invoice.add-item": InvoiceAddItemModal,
          }}
        >
          <MyRoute />
        </ModalsProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
