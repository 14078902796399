import { DateTime } from "luxon";
import { Properties, Constructor } from "./types";

export interface BrandModel extends Properties {}
export class BrandModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    return new BrandModel({
      id: res.id,
      name: res.name,
      createdAt: DateTime.fromISO(res.created_at),
      updatedAt: DateTime.fromISO(res.updated_at),
      deletedAt: res.deleted_at && DateTime.fromISO(res.deleted_at),
    });
  }
}
