import { Text, Stack } from "@mantine/core";
import { FormInput } from "components";
import { DeviceSelectForCreateComponentProps } from "./types";

export function DeviceSelectForCreateComponent(
  props: DeviceSelectForCreateComponentProps
) {
  const { action, device, setDevice } = props;

  if (action !== "create") return null;
  return (
    <Stack>
      <Text>Please select which device do you want to create</Text>
      <FormInput.Device value={device} onChange={setDevice} />
    </Stack>
  );
}
