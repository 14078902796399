import { ModalComponent } from "components";
import { Menu } from "@mantine/core";
import { useModals } from "@mantine/modals";

export function ExportCsvMenuItemComponent() {
  const modals = useModals();

  function handleClick() {
    modals.openContextModal("serial-number.export-csv", {
      title: <ModalComponent.Title>Export Serial Number</ModalComponent.Title>,
      innerProps: {},
    });
  }

  return <Menu.Item onClick={handleClick}>Export CSV</Menu.Item>;
}
