import { usePickupRequest } from "hooks";
import { useParams } from "react-router";

import { Card, Stack } from "@mantine/core";
import { CardDataDisplayComponent } from "components";
import { DeviceInformationForm, SectionTitleComponent } from "..";

export function DeviceInformationSection() {
  const { id } = useParams();
  const { pickupRequest } = usePickupRequest({ id });

  return (
    <Card>
      <Stack>
        <SectionTitleComponent>Device Information</SectionTitleComponent>
        <CardDataDisplayComponent
          label="Serial Number"
          value={pickupRequest?.devices[0].serialNumber.serialNumber}
        />
        <DeviceInformationForm.Device
          deviceId={pickupRequest?.devices[0].serialNumber.deviceId}
        />
        <DeviceInformationForm.Parts parts={pickupRequest?.devices[0].parts} />
        <CardDataDisplayComponent
          label="Complaint"
          value={pickupRequest?.complaint ?? ""}
        />
      </Stack>
    </Card>
  );
}
