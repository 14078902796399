import { useStyles } from "./styles";

import { Select, Skeleton, Stack } from "@mantine/core";
import { SelectComponentProps } from "./types";

export function SelectComponent(props: SelectComponentProps) {
  const { classes } = useStyles();
  const {
    data,
    required,
    label,
    placeholder,
    value,
    disabled,
    isFetching,
    searchable,
    radius,
    onChange,
  } = props;

  if (isFetching) {
    return (
      <Stack>
        <Skeleton height={16} width={150} />
        <Skeleton height={24} width={250} />
      </Stack>
    );
  }

  return (
    <Select
      classNames={classes}
      label={label}
      placeholder={placeholder ?? "Select here..."}
      required={required}
      data={data}
      value={value}
      onChange={onChange}
      disabled={disabled}
      radius={radius ?? 12}
      searchable={searchable ?? true}
    />
  );
}
