import { Menu } from "@mantine/core";
import { RemoveMenuItemComponentProps } from "./types/RemoveMenuItem";
import { useDataProvider } from "../../providers";

export function RemoveMenuItemComponent(props: RemoveMenuItemComponentProps) {
  const { part } = props;
  const { dispatch } = useDataProvider();

  function handleRemoveClick() {
    dispatch({
      type: "REMOVE_WARRANTY",
      part: part,
    });
  }

  return <Menu.Item onClick={handleRemoveClick}>Remove</Menu.Item>;
}
