import React from "react";
import validator from "validator";
import { CreateBrandModalProps } from "./types";
import { net } from "utils/net";

import { Box, Stack } from "@mantine/core";
import { loadingComplete, showLoading } from "utils/loader";
import { ModalComponent, TextInputComponent } from "components";

import { useState, useEffect } from "react";
import { useBrands } from "hooks";
import { useAuth } from "providers/auth";

export function CreateBrandModal(props: CreateBrandModalProps) {
  const { context, id, innerProps } = props;
  const { onSubmit } = innerProps;
  const { mutate } = useBrands();
  const { accessToken } = useAuth();

  const [name, setName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClean, setIsClean] = useState<boolean>(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (validator.isEmpty(name)) return;
    if (!accessToken) return;

    try {
      showLoading("brand.create");
      setIsLoading(true);
      const relativeUrl = `/brands`;
      const body = { name: name };
      const netInstance = net(accessToken);
      await netInstance.post(relativeUrl, body);
      await mutate();
      if (onSubmit) await onSubmit();

      context.closeModal(id);
      loadingComplete("brand.create", false);
    } catch (err) {
      console.log(err);
      loadingComplete("brand.create", true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value);
  }

  useEffect(() => {
    setIsClean(!validator.isEmpty(name));
  }, [name]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <TextInputComponent
          label="Brand Name"
          value={name}
          onChange={handleNameChange}
        />
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          submit={{ label: "Add New", disabled: isLoading || !isClean }}
        />
      </Stack>
    </Box>
  );
}
