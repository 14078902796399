import { LoadingGroupComponent } from "..";
import { BodyComponentProps } from "./types";

export function BodyComponent(props: BodyComponentProps) {
  const { isLoading, colCount, children } = props;

  return (
    <tbody>
      {isLoading ? <LoadingGroupComponent colCount={colCount} /> : children}
    </tbody>
  );
}
