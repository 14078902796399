import { Grid, Stack } from "@mantine/core";
import { BrandInputComponent } from "./BrandInput";
import { NameInputComponent } from "./NameInput";

export function DeviceInputComponent() {
  return (
    <Grid>
      <Grid.Col span={6}>
        <Stack>
          <NameInputComponent />
          <BrandInputComponent />
        </Stack>
      </Grid.Col>
    </Grid>
  );
}
