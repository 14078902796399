import React from "react";
import { EditSerialNumberModalProps } from "./types";
import { DateTime } from "luxon";
import { loadingComplete, showLoading } from "utils/loader";
import { net } from "utils/net";

import { Box, Stack } from "@mantine/core";
import {
  DatePickerComponent,
  FormInput,
  ModalComponent,
  TextInputComponent,
} from "components";

import { useState } from "react";
import { useAuth } from "providers/auth";

export function EditSerialNumberModal(props: EditSerialNumberModalProps) {
  const { context, id, innerProps } = props;
  const { serialNumber, onSubmit } = innerProps;
  const { accessToken } = useAuth();

  const [pd, setPd] = useState<DateTime>(serialNumber.warrantyStartDate);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!accessToken) return;

    try {
      showLoading("serial-number.edit");
      setIsLoading(true);

      const relativeUrl = `/serial-numbers/${serialNumber.id}`;
      const body = { purchase_date: pd.toISO() };
      const netInstance = net(accessToken);
      await netInstance.patch(relativeUrl, body);
      if (onSubmit) await onSubmit();

      loadingComplete("serial-number.edit", false);
      context.closeModal(id);
    } catch (err) {
      loadingComplete("serial-number.edit", true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <TextInputComponent
          label="Serial Number"
          value={serialNumber.serialNumber}
          disabled
        />
        <DatePickerComponent
          label="Purchase Date"
          value={pd}
          onChange={setPd}
        />
        <FormInput.Device value={serialNumber.device} disabled />
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          submit={{ label: "Edit", disabled: isLoading }}
        />
      </Stack>
    </Box>
  );
}
