import { Menu } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { EditMenuItemProps } from "./types";

export function EditMenuItem(props: EditMenuItemProps) {
  const { device } = props;
  const navigate = useNavigate();

  function handleClick() {
    navigate(`${device.id}/edit`);
  }

  return <Menu.Item onClick={handleClick}>View & Edit</Menu.Item>;
}
