import { PaymentStatusBadgeComponent } from "components";
import { PaymentStatusComponentProps } from "./types";
import { usePaymentLink } from "hooks";

export function PaymentStatusComponent(props: PaymentStatusComponentProps) {
  const { invoice } = props;
  const { paymentLink } = usePaymentLink({
    paymentLinkId: invoice.paymentLinkId,
  });

  return <PaymentStatusBadgeComponent paymentLink={paymentLink} />;
}
