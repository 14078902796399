import { createStyles } from "@mantine/core";
import { StyleProps } from "./types";

const useStyles = createStyles((theme, props: StyleProps, getRef) => ({
  text: {
    fontSize: props.size,
    lineHeight: `${props.lineHeight}px`
  }
}));

export default useStyles;