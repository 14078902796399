import { Button } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { ModalComponent } from "components";
import { useDataProvider } from "../../providers";
import { HandleSubmitOptions } from "./types";

export function AddItemButtonComponent() {
  const modal = useModals();
  const { dispatch } = useDataProvider();

  function handleSubmit(options: HandleSubmitOptions) {
    dispatch({
      type: "ADD_ITEM",
      item: {
        itemId: options.itemId,
        itemDescription: options.itemDescription,
        qty: options.qty,
        price: options.price,
      },
    });
  }

  function handleClick() {
    modal.openContextModal("invoice.add-item", {
      title: <ModalComponent.Title>Add Item</ModalComponent.Title>,
      innerProps: {
        onSubmit: handleSubmit,
      },
    });
  }

  return <Button onClick={handleClick}>Add Item</Button>;
}
