// This component only provide the container
// You need to create your own UI for the head.
//
// This component will make you easier to create your own head.
// And to place the head in the right place.
//
import { Portal } from "@mantine/core";
import { HeadComponentProps } from "./types";

export function HeadComponent(props: HeadComponentProps) {
  const { children } = props;

  return <Portal target="#custom-table-head">{children}</Portal>;
}
