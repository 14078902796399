import { PickupRequestModel } from "models";
import { UsePickupRequestProps } from "./types";
import { fetcher } from "utils/net";

import useSWR from "swr";
import { useAuth } from "providers/auth";
import { useEffect, useState } from "react";

export function usePickupRequest(props: UsePickupRequestProps) {
  const { id } = props;

  const [pickupRequest, setPickupRequest] = useState<PickupRequestModel>();
  const { accessToken } = useAuth();
  const { data, isLoading, error, mutate } = useSWR(() => {
    if (!id) return undefined;
    if (!accessToken) return undefined;

    const url = `/pickup-requests/${id}`;
    return [url, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setPickupRequest(undefined);
      return;
    }

    const pr = PickupRequestModel.fromResponse(data);
    setPickupRequest(pr);
  }, [data]);

  return {
    pickupRequest: pickupRequest,
    isLoading: isLoading,
    error: error,
    mutate: mutate,
  };
}
