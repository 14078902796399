import { DateTime } from "luxon";
import { Properties, Constructor } from "./types";

export interface DeviceDeliveryInformationModel extends Properties {}
export class DeviceDeliveryInformationModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    return new DeviceDeliveryInformationModel({
      id: res.id,
      deviceIds: res.device_ids,
      type: res.type,
      actualWeight: parseInt(res.actual_weight),
      insuredValue: parseInt(res.insured_value),
      createdAt: DateTime.fromISO(res.created_at),
      updatedAt: DateTime.fromISO(res.updated_at),
      deletedAt: res.deleted_at && DateTime.fromISO(res.deleted_at),
    });
  }
}
