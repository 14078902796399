import { FC } from "react";
import { Text } from "@mantine/core";
import useStyles from "./styles";
import { Props } from "./types";

const MazutaText: FC<Props> = (props: Props) => {
  const { classes, cx } = useStyles({
    size: props.size,
    lineHeight: props.lineHeight
  });

  return (
    <Text
      className={cx(classes.text, props.rootStyle)}
      component={(props.span) ? "span" : "div"}
      weight={props.weight}
      {...(props.align) ? { align: props.align } : {}}
      {...(props.dimmed) ? { color: "dimmed" } : {}}
    >
      {props.value}
    </Text>
  );
}

export default MazutaText;