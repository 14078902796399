import { Text } from "@mantine/core";
import { ComplaintComponentProps } from "./types";

export function ComplaintComponent(props: ComplaintComponentProps) {
  const { value } = props;

  return (
    <Text style={{ maxWidth: 250 }} lineClamp={3}>
      {value}
    </Text>
  );
}
