export function funcNotImplemented() {
  throw new Error("Not implemented");
}

export function funcEmpty<T>(args: T): T {
  return {} as T;
}

export async function funcNotImplementedAsync() {
  throw new Error("Not implemented");
}
