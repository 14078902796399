import Auth0Config from "configs/auth0";
import axios from "axios";
import { AuthContext } from "./context";
import { ProviderProps } from "./types";

import useDefaultSystem from "hooks/default_system";
import { useLocalStorage } from "@mantine/hooks";
import { useState } from "react";

export function AuthProvider(props: ProviderProps) {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useLocalStorage({
    key: "access_token",
  });
  const { notificationSystem } = useDefaultSystem();

  async function login(email: string, password: string) {
    setIsFetching(true);
    try {
      const url = "https://mazuta.jp.auth0.com/oauth/token";
      const body = {
        grant_type: "password",
        client_id: Auth0Config.clientId,
        username: email,
        password: password,
        audience: Auth0Config.audience,
      };

      const response = await axios.post(url, body);
      setAccessToken(response.data.access_token);
    } catch (err: any) {
      notificationSystem({
        isSuccess: false,
        message: err.response.data.error_description,
      });
    }
    setIsFetching(false);
  }

  function logout() {
    setAccessToken("");
  }

  return (
    <AuthContext.Provider
      value={{
        accessToken: accessToken,
        isFetching: isFetching,
        login: login,
        logout: logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
