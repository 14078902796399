import { DateTime } from "luxon";
import { Properties, Constructor } from "./types";

export interface InternalLocationModel extends Properties {}
export class InternalLocationModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    return new InternalLocationModel({
      id: res.id,
      name: res.name,
      logisticLocation: {
        address: res.logistic_location.address,
        destinationId: res.logistic_location.destination_id,
      },
      type: res.type,
      capabilities: res.capabilities,
      createdAt: DateTime.fromISO(res.created_at),
      updatedAt: DateTime.fromISO(res.updated_at),
      deletedAt: res.deleted_at && DateTime.fromISO(res.deleted_at),
    });
  }
}
