import { useMantineTheme } from "@mantine/core";

import { Text } from "@mantine/core";
import { MenuTitleComponentProps } from "./types";

export function MenuTitleComponent(props: MenuTitleComponentProps) {
  const { title } = props;
  const theme = useMantineTheme();

  return (
    <Text
      transform="uppercase"
      weight={700}
      size="sm"
      color={theme.colors.gray[6]}
      my="xs"
    >
      {title}
    </Text>
  );
}
