import { ViewPaymentLinkModalProps } from "./types";
import { Box, Stack, Text } from "@mantine/core";
import { ButtonComponent } from "components";

export function ViewPaymentLinkModal(props: ViewPaymentLinkModalProps) {
  const { context, id, innerProps } = props;
  const { paymentLink } = innerProps;

  function handleCloseClick() {
    context.closeModal(id);
  }

  return (
    <Box>
      <Stack>
        <Text variant="link">{paymentLink.url}</Text>
        <ButtonComponent variant="outline" onClick={handleCloseClick}>
          Close
        </ButtonComponent>
      </Stack>
    </Box>
  );
}
