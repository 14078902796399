import { Text } from "@mantine/core";
import { useSerialNumberDevice } from "hooks";
import { DeviceNameComponentProps } from "./types";

export function DeviceNameComponent(props: DeviceNameComponentProps) {
  const { serialNumber } = props;
  const { device, isLoading } = useSerialNumberDevice({
    serialNumberId: serialNumber.id,
  });

  if (isLoading) return <></>;
  return (
    <Text size="sm">
      <i>{device?.name}</i>
    </Text>
  );
}
