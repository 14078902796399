import { DateTime } from "luxon";
import { DeviceModel, ExtendedWarrantyModel } from "models";
import { Properties, Constructor } from "./types";

export interface SerialNumberModel extends Properties {}
export class SerialNumberModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    return new SerialNumberModel({
      id: res.id,
      serialNumber: res.serial_number,
      warrantyStartDate: DateTime.fromISO(res.warranty_start_date),
      device: res.device && DeviceModel.fromResponse(res.device),
      deviceId: res.device_id && res.device_id,
      extendedWarranty: res.extended_warranty?.map((data: any) => {
        return ExtendedWarrantyModel.fromResponse(data);
      }),
      createdAt: DateTime.fromISO(res.created_at),
      updatedAt: DateTime.fromISO(res.updated_at),
      deletedAt: res.deleted_at && DateTime.fromISO(res.deleted_at),
    });
  }
}
