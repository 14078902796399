import { FC } from "react";
import { Box, Group, Stack } from "@mantine/core";
import { ReactSVG } from "react-svg";
import useStyles from "./styles";
import { ButtonComponent } from "components";
import DisplayQueryComponent from "components/DisplayQuery";

const Header: FC = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.header} py={40}>
      <DisplayQueryComponent condition="smaller" size="sm">
        <Group position="apart" mx={150}>
          <ReactSVG src="/assets/images/logo.svg" />
          {/* <ButtonComponent rootStyle={classes.buttonHeader} onClick={() => {}}>
            Login
          </ButtonComponent> */}
        </Group>
      </DisplayQueryComponent>
      <DisplayQueryComponent condition="larger" size="sm">
        <Stack mx="xl">
          <ReactSVG src="/assets/images/logo.svg" />
          <Box>
            <ButtonComponent
              rootStyle={classes.buttonHeader}
              onClick={() => {}}
            >
              Login
            </ButtonComponent>
          </Box>
        </Stack>
      </DisplayQueryComponent>
    </Box>
  );
};

export default Header;
