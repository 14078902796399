import { DateTime } from "luxon";
import { SerialNumberModel } from "models/serial-number";
import { Properties, Constructor } from "./types";

export interface SaleTransactionModel extends Properties {}
export class SaleTransactionModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    const processFile = (res: Record<string, any>) => ({
      provider: res.provider,
      id: res.id,
      url: res.url,
    });

    return new SaleTransactionModel({
      id: res.id,
      serialNumberId: res.serial_number_id,
      serialNumber:
        res.serial_number && SerialNumberModel.fromResponse(res.serial_number),
      createdBy: res.created_by && res.created_by,
      files: res.files && {
        invoice: processFile(res.files.invoice),
        serialNumber: processFile(res.files.serial_number),
        device: processFile(res.files.device),
      },
      transactionDate: DateTime.fromISO(res.transaction_date),
      createdAt: DateTime.fromISO(res.created_at),
      updatedAt: DateTime.fromISO(res.updated_at),
      deletedAt: res.deleted_at && DateTime.fromISO(res.deleted_at),
    });
  }
}
