import { useAuth } from "providers/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { loadingComplete, showLoading } from "utils/loader";

import { Box, Loader } from "@mantine/core";

export function LogoutPage() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const handleLogout = async () => {
      const sleep = (ms: number) => {
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
      };

      showLoading("logout");
      logout();
      await sleep(5000);
      loadingComplete("logout", false, "Logged out");
      await sleep(2000);
      navigate("/login");
    };

    handleLogout();
  }, [logout, navigate]);

  return (
    <Box
      component="main"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Loader />
    </Box>
  );
}
