import { FC } from "react";
import { Box } from "@mantine/core";
import useStyles from "./styles";
import { Props } from "./types";

const Template: FC<Props> = (props: Props) => {
  const { children } = props;
  const { classes } = useStyles();

  return (
    <Box className={classes.box}>
      <Box className={classes.boxColor}>
        {children}
      </Box>
    </Box>
  );
}

export default Template;