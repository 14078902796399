import { SparePartModel } from "models/spare-part";
import { Properties, Constructor } from "./types";

export interface WarrantyModel extends Properties {}
export class WarrantyModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    return new WarrantyModel({
      part: res.part && SparePartModel.fromResponse(res.part),
      partId: res.part_id,
      warrantyPeriod: res.warranty_period,
    });
  }
}
