import { createStyles } from "@mantine/core";
import { StyleProps } from "./types";

export const useStyles = createStyles((theme, props: StyleProps, getRef) => ({
  root: {
    height:
      props.mainStyle === "large"
        ? props.height ?? 52
        : props.mainStyle === "medium"
        ? props.height ?? 37
        : props.height ?? 29,
    padding:
      props.mainStyle === "large"
        ? "12px 16px"
        : props.mainStyle === "medium"
        ? "8px 12px"
        : "4px 12px",
  },
  label: {
    fontSize: props.mainStyle === "large" ? 18 : 14,
    lineHeight: props.mainStyle === "large" ? "27px" : "21px",
    fontWeight: props.mainStyle === "small" ? 400 : 700,
  },
}));
