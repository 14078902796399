import { useState } from "react";

import { AppShell, Space } from "@mantine/core";
import { MainTemplateComponentProps } from "./types";
import { CustomNavbarComponent, HeaderComponent } from "./components";

export function MainTemplateComponent(props: MainTemplateComponentProps) {
  const { children } = props;
  const [opened, setOpened] = useState(false);

  function toggleOpen() {
    setOpened((o) => !o);
  }

  return (
    <AppShell
      header={<HeaderComponent opened={opened} toggleOpen={toggleOpen} />}
      style={{ backgroundColor: "#F5F5F5" }}
      navbar={
        <CustomNavbarComponent opened={opened}>
          <CustomNavbarComponent.MenuSection>
            <CustomNavbarComponent.MenuTitle title="Menu" />
            <CustomNavbarComponent.MenuItem
              label="Product Serial Number"
              to="/product-serial-numbers"
            />
            <CustomNavbarComponent.MenuItem
              label="Warehouse Transactions"
              to="/warehouse-transactions"
            />
            <CustomNavbarComponent.MenuItem
              label="Sale Transactions"
              to="/sale-transactions"
            />
            <CustomNavbarComponent.MenuItem
              label="Pickup Requests"
              to="/pickup-requests"
            />
            <CustomNavbarComponent.MenuItem label="Invoices" to="/invoices" />
            <Space h="md" />
            <CustomNavbarComponent.MenuTitle title="Configuration" />
            <CustomNavbarComponent.MenuItem label="Brands" to="/brands" />
            <CustomNavbarComponent.MenuItem label="Devices" to="/devices" />
            <CustomNavbarComponent.MenuItem
              label="Spare Parts"
              to="/spare-parts"
            />
          </CustomNavbarComponent.MenuSection>
          <CustomNavbarComponent.FooterSection>
            <CustomNavbarComponent.MenuItem label="Log Out" to="/logout" />
          </CustomNavbarComponent.FooterSection>
        </CustomNavbarComponent>
      }
      fixed
    >
      {children}
    </AppShell>
  );
}
