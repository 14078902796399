import { Text, Stack, Group } from "@mantine/core";
import { TableComponent } from "components";
import { AddWarrantyButtonComponent } from "./AddWarrantyButton";
import { TableItemsComponent } from "./TableItems";

import { useStyles } from "./styles";

export function WarrantyInputComponent() {
  const { classes } = useStyles();

  return (
    <Stack>
      <Group position="apart">
        <Text className={classes.label}>Warranty</Text>
        <AddWarrantyButtonComponent />
      </Group>
      <TableComponent>
        <thead>
          <tr>
            <th>Part</th>
            <th>Warranty Period</th>
            <th></th>
          </tr>
        </thead>
        <TableComponent.Body colCount={3} isLoading={false}>
          <TableItemsComponent />
        </TableComponent.Body>
      </TableComponent>
    </Stack>
  );
}
