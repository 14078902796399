import {
  OrderCreatedButtonComponent,
  OrderReceivedButtonComponent,
  CancelButtonComponent,
} from "./components";

export function StatusStepperForm() {
  return <></>;
}

StatusStepperForm.OrderCreatedButton = OrderCreatedButtonComponent;
StatusStepperForm.OrderReceivedButton = OrderReceivedButtonComponent;
StatusStepperForm.CancelButton = CancelButtonComponent;
