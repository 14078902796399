// This component only provide the container
// You need to create your own UI for the pagination.
//
// This component will make you easier to create your own pagination.
// And to place the pagination in the right place.
//
import { Portal } from "@mantine/core";
import { PaginationComponentProps } from "./types";

export function PaginationComponent(props: PaginationComponentProps) {
  const { children } = props;

  return <Portal target="#custom-table-pagination">{children}</Portal>;
}
