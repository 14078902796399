import { useTableProvider } from "../../providers";

import { MainContentComponent, TableComponent } from "components";
import {
  HeaderLeft,
  TableHeadComponent,
  TableItemsComponent,
  TablePaginationComponent,
} from "..";

export function MainComponent() {
  const { isLoading } = useTableProvider();

  return (
    <MainContentComponent headerLeft={<HeaderLeft />}>
      <TableComponent>
        <TableComponent.Head>
          <TableHeadComponent />
        </TableComponent.Head>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Requester</th>
            <th>Complaint</th>
            <th>Courier</th>
            <th>Service Center</th>
            <th>Status Pembayaran</th>
            <th>Request Date</th>
            <th></th>
          </tr>
        </thead>
        <TableComponent.Body colCount={8} isLoading={isLoading}>
          <TableItemsComponent />
        </TableComponent.Body>
        <TableComponent.Pagination>
          <TablePaginationComponent />
        </TableComponent.Pagination>
      </TableComponent>
    </MainContentComponent>
  );
}
