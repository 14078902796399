import { zodResolver } from "@mantine/form";
import validator from "validator";
import { z } from "zod";

type ZodShape<T> = { [key in keyof T]: z.ZodEffects<z.ZodType<T[key]>>; };
type TFormSchema = typeof formProps;

const formProps = {
  email: '',
  password: ''
};
  
const schemaObject: ZodShape<TFormSchema> = {
  email: z.string()
    .refine((value) => (
      validator.isEmail(value)
    ), { message: "Invalid email" }),
  password: z.string()
    .refine((value) => (
      validator.isStrongPassword(value)
    ), { message: "Password must be at least 8 characters (includes: 1 lowercase, 1 uppercase, 1 number, 1 symbol)" })
};

const formInit = {
  initialValues: { ...formProps },
  schema: zodResolver(z.object(schemaObject).strict())
};

export type { TFormSchema };
export default { formInit } as const;