import { Text, Stack } from "@mantine/core";
import { TemplateDownloadSectionComponentProps } from "./types";

export function TemplateDownloadSectionComponent(
  props: TemplateDownloadSectionComponentProps
) {
  const { action } = props;

  function generateUrl(action: string) {
    if (action === "create") {
      return "https://res.cloudinary.com/monosolusi/raw/upload/v1675008089/Mazuta/mazuta_sn_data_-_Sheet1_31_dsg5fj.csv";
    } else if (action === "delete") {
      return "https://res.cloudinary.com/monosolusi/raw/upload/v1682569552/Mazuta/mazuta_delete_template_ycfpv6.csv";
    } else throw new Error("Invalid action");
  }

  return (
    <Stack>
      <Text>Please download the template by clicking the link below</Text>
      <Text
        component="a"
        href={generateUrl(action)}
        target="_blank"
        color="primary"
      >
        Download Import CSV Template
      </Text>
    </Stack>
  );
}
