import { CustomTableComponentProps } from "./types";
import { useStyles } from "./styles";

import { Box, Paper, Table } from "@mantine/core";
import {
  HeadComponent,
  BodyComponent,
  PaginationComponent,
  TableInput,
} from "./components";

export function TableComponent(props: CustomTableComponentProps) {
  const { children } = props;
  const { classes } = useStyles();

  return (
    <>
      <Paper shadow="xs" pb="xl">
        <Box id="custom-table-head" mt="md" p="xs"></Box>
        <Table verticalSpacing="md" highlightOnHover>
          {children}
        </Table>
      </Paper>
      <Box
        id="custom-table-pagination"
        mt="md"
        className={classes.paginationWrapper}
      ></Box>
    </>
  );
}

TableComponent.Head = HeadComponent;
TableComponent.Body = BodyComponent;
TableComponent.Pagination = PaginationComponent;
TableComponent.TableInput = TableInput;
