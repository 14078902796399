import { ModalComponent } from "components";
import { Menu } from "@mantine/core";
import { useModals } from "@mantine/modals";

export function AddNewSerialNumberMenuItemComponent() {
  const modals = useModals();

  function handleClick() {
    modals.openContextModal("serial-number.create", {
      title: <ModalComponent.Title>Add New Serial Number</ModalComponent.Title>,
      innerProps: {},
    });
  }

  return <Menu.Item onClick={handleClick}>Add New SN</Menu.Item>;
}
