import React from "react";
import CurrencyUtils from "utils/currency";
import validator from "validator";
import { InvoiceAddItemModalProps } from "./types";
import { Box, Stack } from "@mantine/core";
import { ModalComponent, TextInputComponent } from "components";
import { useState, useEffect } from "react";

export function InvoiceAddItemModal(props: InvoiceAddItemModalProps) {
  const { context, id, innerProps } = props;
  const { onSubmit } = innerProps;

  const [itemId, setItemId] = useState<string>("");
  const [itemDescription, setItemDescription] = useState<string>("");
  const [qty, setQty] = useState<number>(1);
  const [price, setPrice] = useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);
  const [isClean, setIsClean] = useState<boolean>(false);

  function cleanUpForm() {
    setItemId("");
    setItemDescription("");
    setQty(1);
    setPrice(0);
    setSubtotal(0);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (onSubmit) {
      onSubmit({
        itemId: itemId,
        itemDescription: itemDescription,
        qty: qty,
        price: price,
      });
    }

    cleanUpForm();

    context.closeModal(id);
  }

  function handleItemIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    setItemId(e.currentTarget.value);
  }

  function handleItemDescriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    setItemDescription(e.currentTarget.value);
  }

  function handleQtyChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.value === "") setQty(0);
    else setQty(parseInt(e.currentTarget.value));
  }

  function handlePriceChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.value === "") setPrice(0);
    else setPrice(parseInt(e.currentTarget.value));
  }

  useEffect(() => {
    setSubtotal(qty * price);
  }, [qty, price]);

  useEffect(() => {
    setIsClean(
      !validator.isEmpty(itemId) && !validator.isEmpty(itemDescription)
    );
  }, [itemId, itemDescription]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <TextInputComponent
          label="Item ID"
          value={itemId}
          onChange={handleItemIdChange}
        />
        <TextInputComponent
          label="Item Description"
          value={itemDescription}
          onChange={handleItemDescriptionChange}
        />
        <TextInputComponent
          label="Qty"
          value={qty.toString()}
          onChange={handleQtyChange}
        />
        <TextInputComponent
          label="Price"
          value={price.toString()}
          onChange={handlePriceChange}
        />
        <TextInputComponent
          label="Subtotal"
          value={CurrencyUtils.toCurrency(subtotal)}
          disabled
        />
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          submit={{ label: "Add New", disabled: !isClean }}
        />
      </Stack>
    </Box>
  );
}
