import { loadingComplete, showLoading } from "utils/loader";
import { net } from "utils/net";

import { useAuth } from "providers/auth";
import { useState } from "react";
import { useDataProvider } from "../../providers";
import { useNavigate } from "react-router-dom";
import { Button } from "@mantine/core";
import { parsePhoneNumber } from "libphonenumber-js";

export function AddNewButtonComponent() {
  const navigate = useNavigate();
  const { data, isClean } = useDataProvider();
  const { accessToken } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleClick() {
    if (!isClean) return;
    if (!accessToken) return;
    if (!data.customerInformation) return;
    if (!data.customerInformation.phoneNumber) return;

    try {
      showLoading("invoice.add-new");
      setIsLoading(true);

      const { phoneNumber: strPhoneNumber } = data.customerInformation;
      const phoneNumber = parsePhoneNumber(strPhoneNumber, "ID");

      const relUrl = `/invoices`;
      const body = {
        serial_number: data.serialNumber,
        items: data.items.map((i) => ({
          item_id: i.itemId,
          item_description: i.itemDescription,
          qty: i.qty,
          price: i.price,
        })),
        source: { type: data.sourceType },
        customer_information: {
          name: data.customerInformation.name,
          phone_number: phoneNumber.number,
        },
      };

      const netInstance = net(accessToken);
      await netInstance.post(relUrl, body);

      loadingComplete("invoice.add-new", false);
      navigate(-1);
    } catch (err) {
      loadingComplete("invoice.add-new", true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button disabled={!isClean || isLoading} onClick={handleClick}>
      Save Data
    </Button>
  );
}
