import { useModals } from "@mantine/modals";
import { useTableProvider } from "../../providers";

import { Menu } from "@mantine/core";
import { ModalComponent } from "components";

export function ImportCsvMenuItem() {
  const modals = useModals();
  const { refreshTable } = useTableProvider();

  function handleClick() {
    modals.openContextModal("warehouse-transaction.import-csv", {
      title: <ModalComponent.Title>Import CSV</ModalComponent.Title>,
      innerProps: {
        onSubmit: async () => await refreshTable(),
      },
    });
  }

  return <Menu.Item onClick={handleClick}>Import CSV</Menu.Item>;
}
