import lodash from "lodash";
import { SerialNumberModel } from "models";
import { UseSerialNumbersProps } from "./types";
import { fetcher } from "utils/net";

import useSWR from "swr";
import { useAuth } from "providers/auth";
import { useEffect, useState } from "react";

export function useSerialNumbers(props?: UseSerialNumbersProps) {
  const limit = lodash(props).get("limit", 5);
  const offset = lodash(props).get("offset", 0);
  const { filter } = props ?? {};

  const [serialNumbers, setSerialNumbers] = useState<SerialNumberModel[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [itemPerPage, setItemPerPage] = useState<number>(0);

  const { accessToken } = useAuth();
  const { data, error, isLoading, mutate } = useSWR(() => {
    if (!accessToken) return undefined;

    const baseUrl = `/serial-numbers?limit=${limit}&offset=${offset}`;
    if (filter) {
      // Create filter object that needs to be appended to URL SearchPaarams
      const xFilter = lodash({
        serial_number: filter.serialNumber,
        warranty_start_date: filter.warrantyStartDate,
        warranty_end_date: filter.warrantyEndDate,
      })
        .omitBy(lodash.isNil)
        .value();

      if (Object.keys(xFilter).length === 0) return [baseUrl, accessToken];

      // Now create the params based on the xFilter object
      // Since the xFilter is object, we need to convert it to URLSearchParams
      const params = new URLSearchParams();
      Object.keys(xFilter).forEach((key) => {
        const value = lodash(xFilter).get(key);
        params.append(key, value);
      });

      return [`${baseUrl}&${params.toString()}`, accessToken];
    } else return [baseUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) return;

    const ss = data.data.map((d: any) => SerialNumberModel.fromResponse(d));
    setSerialNumbers(ss);

    // We need to set the totalCount
    // And itemPerPage based on the data.pagination.totalCount and data.pagination.limit
    if (!data.pagination) return;
    setTotalCount(data.pagination.total_count);
    setItemPerPage(data.pagination.limit);
  }, [data]);

  return {
    serialNumbers: serialNumbers,
    isLoading: isLoading,
    error: error,
    totalCount: totalCount,
    itemPerPage: itemPerPage,
    mutate: mutate,
  };
}
