import {
  PaymentStatusComponent,
  InvoiceSourceComponent,
  CurrencyComponent,
  SerialNumberComponent,
} from "./components";

export function TableItemComponent() {
  return <></>;
}

TableItemComponent.PaymentStatus = PaymentStatusComponent;
TableItemComponent.InvoiceSource = InvoiceSourceComponent;
TableItemComponent.Currency = CurrencyComponent;
TableItemComponent.SerialNumber = SerialNumberComponent;
