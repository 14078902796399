import { showNotification, updateNotification } from "@mantine/notifications";
import { Check, X } from "tabler-icons-react";

export function showLoading(id: string) {
  showNotification({
    id: id,
    title: "Please wait",
    message: "Your request is being processed",
    color: "cyan",
    loading: true,
    autoClose: false,
  });
}

export function loadingComplete(
  id: string,
  isFailed: boolean,
  message?: string
) {
  const generateMessage = (isFailed: boolean) => {
    if (isFailed) {
      return {
        title: "Failed",
        message:
          message ??
          "We cannot process your request properly. Please try again in few minutes.",
        color: "red",
        icon: <X />,
      };
    } else {
      return {
        title: "Done",
        message: message ?? "Successfully processed your request",
        color: "cyan",
        icon: <Check />,
      };
    }
  };

  updateNotification({
    id: id,
    loading: false,
    autoClose: 5000,
    ...generateMessage(isFailed),
  });
}
