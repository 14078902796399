import { useModals } from "@mantine/modals";
import { useTableProvider } from "../../providers";
import { ModalComponent } from "components";
import { Menu } from "@mantine/core";

export function ImportCsvMenuItemComponent() {
  const modals = useModals();
  const { refreshTable } = useTableProvider();

  function handleClick() {
    modals.openContextModal("serial-number.import-csv", {
      title: <ModalComponent.Title>Import Serial Number</ModalComponent.Title>,
      innerProps: {
        onSubmit: async () => await refreshTable(),
      },
    });
  }

  return <Menu.Item onClick={handleClick}>Import CSV</Menu.Item>;
}
