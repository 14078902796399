import { Menu } from "@mantine/core";
import { EditMenuItemProps } from "./types";
import { ModalComponent } from "components";

import { useModals } from "@mantine/modals";
import { useTableProvider } from "../../providers";

export function EditMenuItem(props: EditMenuItemProps) {
  const { serialNumber } = props;

  const modals = useModals();
  const { refreshTable } = useTableProvider();

  function handleClick() {
    modals.openContextModal("serial-number.edit", {
      title: <ModalComponent.Title>Edit Serial Number</ModalComponent.Title>,
      innerProps: {
        serialNumber: serialNumber,
        onSubmit: async () => await refreshTable(),
      },
    });
  }

  return <Menu.Item onClick={handleClick}>View & Edit</Menu.Item>;
}
