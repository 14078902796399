import { DeviceModel } from "models";
import { UseSerialNumberDeviceProps } from "./types";
import { fetcher } from "utils/net";

import useSWR from "swr";
import { useAuth } from "providers/auth";
import { useEffect, useState } from "react";

export function useSerialNumberDevice(props: UseSerialNumberDeviceProps) {
  const [device, setDevice] = useState<DeviceModel>();

  const { serialNumberId } = props;
  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!accessToken) return undefined;
    if (!serialNumberId) return undefined;

    const relUrl = `/serial-numbers/${serialNumberId}/device`;
    return [relUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data || data === undefined) {
      setDevice(undefined);
      return;
    } else {
      const device = DeviceModel.fromResponse(data);
      setDevice(device);
    }
  }, [data]);

  return { device: device, error: error, isLoading: isLoading };
}
