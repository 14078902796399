import { TextInputComponent } from "components";
import { useDataProvider } from "../../providers";

export function NameInputComponent() {
  const { data, dispatch } = useDataProvider();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: "SET_DATA",
      key: "name",
      value: e.target.value,
    });
  }

  return (
    <TextInputComponent
      label="Name"
      value={data.name}
      onChange={handleChange}
      required
    />
  );
}
