import { TextInputComponent } from "components";
import { useDataProvider } from "../../../../providers";

export function SerialNumberComponent() {
  const { data, dispatch } = useDataProvider();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: "SET_DATA",
      key: "serialNumber",
      value: e.currentTarget.value,
    });
  }

  return (
    <TextInputComponent
      value={data.serialNumber}
      onChange={handleChange}
      label="Serial Number"
    />
  );
}
