import { Menu } from "@mantine/core";
import { useNavigate } from "react-router";

export function CreateNewDeviceMenuItem() {
  const navigate = useNavigate();

  function handleClick() {
    navigate("add-new");
  }

  return <Menu.Item onClick={handleClick}>Create New Device</Menu.Item>;
}
