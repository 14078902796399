import useSWR from "swr";
import { useAuth } from "providers/auth";
import { UsePaymentLinkProps } from "./types";
import { fetcher } from "utils/net";
import { useEffect, useState } from "react";
import { PaymentLinkModel } from "models";

export function usePaymentLink(props: UsePaymentLinkProps) {
  const [paymentLink, setPaymentLink] = useState<PaymentLinkModel>();

  const { paymentLinkId } = props;
  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!accessToken) return undefined;
    if (!paymentLinkId) return undefined;

    const relUrl = `/payment-links/${paymentLinkId}`;
    return [relUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setPaymentLink(undefined);
      return;
    }

    const paymentLink = PaymentLinkModel.fromResponse(data);
    setPaymentLink(paymentLink);
  }, [data]);

  return {
    paymentLink: paymentLink,
    error: error,
    isLoading: isLoading,
  };
}
