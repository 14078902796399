import { Box } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { DateTime } from "luxon";
import { ReactSVG } from "react-svg";
import { useStyles } from "./styles";
import { DatePickerComponentProps } from "./types";

function RightSection() {
  const { classes } = useStyles();

  return (
    <Box pr={30}>
      <ReactSVG
        beforeInjection={(svg) => {
          svg.setAttribute("class", classes.svg);
        }}
        src="/assets/icons/calendar.svg"
      />
    </Box>
  );
}

export function DatePickerComponent(props: DatePickerComponentProps) {
  const { classes } = useStyles();
  const {
    label,
    placeholder,
    required,
    value,
    initialLevel,
    labelFormat,
    inputFormat,
    disabled,
    onChange,
  } = props;

  function handleChange(newDate: Date) {
    if (onChange) onChange(DateTime.fromJSDate(newDate));
  }

  return (
    <DatePicker
      classNames={classes}
      label={label}
      required={required}
      placeholder={placeholder}
      value={value?.toJSDate()}
      onChange={onChange ? handleChange : undefined}
      rightSection={<RightSection />}
      initialLevel={initialLevel}
      labelFormat={labelFormat ?? "DD MMMM YYYY"}
      inputFormat={inputFormat ?? "DD MMMM YYYY"}
      disabled={disabled}
    />
  );
}
