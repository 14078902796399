import { TextInputComponent } from "components";
import { PhoneNumberInputComponentProps } from "./types";

export function PhoneNumberInputComponent(
  props: PhoneNumberInputComponentProps
) {
  const { label, value, placeholder, onChange } = props;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    // We need to append +62 in front of the phone number.
    // However, if the phone number already have +62 in front of it, we don't need to append it again.
    // So, we need to check if the phone number already have +62 in front of it.
    // If it does, then we don't need to append it again.
    // If it doesn't, then we need to append it.
    // The phone number should be in the format of +62XXXXXXXXXXXX
    if (!onChange) return;

    const phoneNumber = e.target.value;
    if (phoneNumber.startsWith("+62")) {
      onChange(phoneNumber);
    } else {
      onChange(`+62${phoneNumber}`);
    }
  }

  return (
    <TextInputComponent
      label={label ?? "Phone Number"}
      placeholder={placeholder ?? "+628123456789"}
      value={value}
      onChange={handleChange}
    />
  );
}
