import { MainTemplateComponent } from "components";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "providers/auth";

export function PrivateRouteComponent() {
  const { accessToken } = useAuth();

  if (!accessToken) return <Navigate to="/login" replace={true} />;
  return (
    <MainTemplateComponent>
      <Outlet />
    </MainTemplateComponent>
  );
}
