import { DeviceDeleteModalProps } from "./types";
import { loadingComplete, showLoading } from "utils/loader";
import { net } from "utils/net";

import { ModalComponent } from "components";
import { Box, Stack, Text } from "@mantine/core";

import { useAuth } from "providers/auth";
import { useState } from "react";

export function DeviceDeleteModal(props: DeviceDeleteModalProps) {
  const { context, id, innerProps } = props;
  const { device, onSubmit } = innerProps;
  const { accessToken } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!accessToken) return;

    try {
      showLoading("device.delete");
      setIsLoading(true);
      const relativeUrl = `/devices/${device.id}`;
      const netInstance = net(accessToken);
      await netInstance.delete(relativeUrl);
      if (onSubmit) await onSubmit();

      context.closeModal(id);
      loadingComplete("device.delete", false);
    } catch (err) {
      loadingComplete("device.delete", true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        <Box>
          <Text>
            Your <strong>{device.name}</strong> device will be deleted. Please
            note that you can not recover the deleted device.
          </Text>
        </Box>
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          submit={{ label: "Delete", disabled: isLoading }}
        />
      </Stack>
    </form>
  );
}
