import { Text } from "@mantine/core";
import { FilesComponentProps } from "./types/Files";

export function FilesComponent(props: FilesComponentProps) {
  const { transaction } = props;

  if (!transaction.files) return <Text>-</Text>;
  return (
    <>
      <Text
        component="a"
        href={transaction.files && transaction.files.invoice.url}
        variant="link"
      >
        Invoice
      </Text>
      <br />
      <Text
        component="a"
        href={transaction.files && transaction.files.serialNumber.url}
        variant="link"
      >
        Serial Number
      </Text>
      <br />
      <Text
        component="a"
        href={transaction.files && transaction.files.device.url}
        variant="link"
      >
        Device
      </Text>
    </>
  );
}
