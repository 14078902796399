import { Button, Divider, Menu } from "@mantine/core";
import { ChevronDown } from "tabler-icons-react";
import { HeaderMenuItem } from "../HeaderMenuItem";

export function HeaderRight() {
  return (
    <Menu
      control={
        <Button rightIcon={<ChevronDown />} uppercase>
          Menu
        </Button>
      }
    >
      <HeaderMenuItem.AddNewTransaction />
      <Divider />
      <HeaderMenuItem.ImportCsv />
    </Menu>
  );
}
