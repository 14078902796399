import { Group, Stack, Text } from "@mantine/core";
import { TableComponent } from "components";
import { AddItemButtonComponent, TableItemsComponent } from "..";

export function InvoiceItemsComponent() {
  return (
    <Stack>
      <Group position="apart">
        <Text
          style={{
            color: "#212121",
            fontSize: 18,
            fontWeight: 700,
            lineHeight: "27px",
          }}
        >
          Items
        </Text>
        <AddItemButtonComponent />
      </Group>
      <TableComponent>
        <thead>
          <tr>
            <th>Item ID</th>
            <th>Item Description</th>
            <th>QTY</th>
            <th>Price</th>
            <th>Subtotal</th>
            <th></th>
          </tr>
        </thead>
        <TableComponent.Body colCount={6} isLoading={false}>
          <TableItemsComponent />
        </TableComponent.Body>
      </TableComponent>
    </Stack>
  );
}
