import { TableProvider } from "./providers";
import { MainComponent } from "./components";

export function BrandPage() {
  return (
    <TableProvider>
      <MainComponent />
    </TableProvider>
  );
}
