import { Grid, Stack } from "@mantine/core";
import { Metadata } from "..";

export function InvoiceMetadataComponent() {
  return (
    <Grid>
      <Grid.Col span={6}>
        <Stack>
          <Metadata.CustomerName />
          <Metadata.PhoneNumber />
          <Metadata.InvoiceType />
        </Stack>
      </Grid.Col>
      <Grid.Col span={6}>
        <Stack>
          <Metadata.SerialNumber />
          <Metadata.DeviceInformation />
          <Metadata.Total />
        </Stack>
      </Grid.Col>
    </Grid>
  );
}
