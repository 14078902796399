import { ButtonComponent } from "./Button";
import { SearchInputComponent } from "./SearchInput";
import { TextInputComponent } from "./TextInput";

export function TableInput() {
  return <></>;
}

TableInput.TextInput = TextInputComponent;
TableInput.SearchInput = SearchInputComponent;
TableInput.Button = ButtonComponent;
