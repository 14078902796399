import React from "react";
import validator from "validator";
import { DateTime } from "luxon";
import { CreateWarehouseTransactionModalProps } from "./types";
import { loadingComplete, showLoading } from "utils/loader";
import { net, responseChecker } from "utils/net";

import { Box, Stack } from "@mantine/core";
import {
  DatePickerComponent,
  ModalComponent,
  TextInputComponent,
} from "components";

import { useState, useEffect } from "react";
import { useAuth } from "providers/auth";
import { SerialNumberModel } from "models";

export function CreateWarehouseTransactionModal(
  props: CreateWarehouseTransactionModalProps
) {
  const { context, id, innerProps } = props;
  const { onSubmit } = innerProps;
  const { accessToken } = useAuth();

  const [serialNumber, setSerialNumber] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClean, setIsClean] = useState<boolean>(false);
  const [transactionDate, setTransactionDate] = useState<DateTime>(
    DateTime.now()
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!isClean) return;
    if (!accessToken) return;

    try {
      showLoading("warehouse-transaction.create");
      setIsLoading(true);

      const netInstance = net(accessToken);
      const checkSerialNumber = async (serialNumber: string) => {
        const qs = new URLSearchParams({ serial_number: serialNumber });
        const relativeUrl = `/serial-numbers/find?${qs.toString()}`;
        const res = await netInstance.get(relativeUrl);
        const data = responseChecker(res);
        const sn = SerialNumberModel.fromResponse(data);
        return sn;
      };

      const createTransaction = async (
        serialNumberId: string,
        transactionDate: DateTime
      ) => {
        const relativeUrl = `/warehouse-transactions`;
        const body = {
          serial_number_id: serialNumberId,
          direction: "out",
          transaction_date: transactionDate.toISO(),
        };

        const res = await netInstance.post(relativeUrl, body);
        responseChecker(res);
      };

      const checkedSn = await checkSerialNumber(serialNumber);
      await createTransaction(checkedSn.id, transactionDate);
      if (onSubmit) await onSubmit();

      loadingComplete("warehouse-transaction.create", false);
      context.closeModal(id);
    } catch (err) {
      loadingComplete("warehouse-transaction.create", true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleSerialNumberChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSerialNumber(e.target.value);
  }

  useEffect(() => {
    setIsClean(!validator.isEmpty(serialNumber));
  }, [serialNumber]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <TextInputComponent
          label="Serial Number"
          value={serialNumber}
          onChange={handleSerialNumberChange}
        />
        <DatePickerComponent
          label="Transaction Date"
          value={transactionDate}
          onChange={setTransactionDate}
        />

        <ModalComponent.ActionButtons
          context={context}
          id={id}
          submit={{ label: "Add New", disabled: isLoading || !isClean }}
        />
      </Stack>
    </Box>
  );
}
