import { FC } from "react";
import { Center, Container, Image, SimpleGrid, Stack } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { ButtonComponent } from "components";
import TextComponent from "components/Text";

const NotFound: FC = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <Center my={50}>
      <Container>
        <SimpleGrid
          breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 40 }]}
          className={classes.simpleGrid}
          cols={2}
          spacing={80}
        >
          <Image src="/assets/images/404.svg" withPlaceholder />
          <Stack>
            <TextComponent
              lineHeight={48}
              size={32}
              weight={900}
              value="Something is not right..."
            />
            <TextComponent
              dimmed
              lineHeight={27}
              size={18}
              weight={400}
              value="Page you are trying to open does not exist. You may have mistyped the address, or the
              page has been moved to another URL. If you think this is an error contact support."
            />
            <ButtonComponent
              mainStyle="medium"
              variant="outline"
              onClick={() => navigate(-1)}
            >
              Back to previous page
            </ButtonComponent>
          </Stack>
        </SimpleGrid>
      </Container>
    </Center>
  );
};

export default NotFound;
