import { useMantineTheme } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";

import { Text, Group, ThemeIcon, UnstyledButton } from "@mantine/core";
import { MenuItemComponentProps } from "./types";
import { useEffect, useState } from "react";

export function MenuItemComponent(props: MenuItemComponentProps) {
  const { label, icon, to } = props;
  const [isActive, setIsActive] = useState<boolean>(false);
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const location = useLocation();

  function handleNavigate() {
    navigate(to);
  }

  useEffect(() => {
    const regex = new RegExp(`(${to}/.{0,})|(${to}){1}`, "g");
    const isActive = location.pathname.match(regex)?.length ? true : false;
    setIsActive(isActive);
  }, [location.pathname, to]);

  return (
    <UnstyledButton
      sx={(theme) => ({
        display: "block",
        width: "100%",
        padding: theme.spacing.xs,
        marginBottom: theme.spacing.xs / 3,
        borderRadius: theme.radius.sm,
        color: theme.white,
        backgroundColor: isActive ? theme.colors.brand[6] : "transparent",
        "&:hover": {
          backgroundColor: isActive
            ? theme.colors.brand[6]
            : theme.colors.gray[0],
          color: isActive ? theme.white : theme.black,
        },
      })}
      onClick={handleNavigate}
    >
      <Group>
        {icon && (
          <ThemeIcon color={theme.primaryColor[6]} variant="light">
            {icon}
          </ThemeIcon>
        )}

        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  );
}
