import React from "react";
import lodash from "lodash";
import { Box, Text, Stack } from "@mantine/core";
import { DatePickerComponent, ModalComponent } from "components";
import { ExportSerialNumberModalProps } from "./types";
import { loadingComplete, showLoading } from "utils/loader";
import { net, download } from "utils/net";

import { useEffect, useState } from "react";
import { useAuth } from "providers/auth";
import { DateTime } from "luxon";

export function ExportSerialNumberModal(props: ExportSerialNumberModalProps) {
  const { context, id } = props;

  const now = DateTime.now().setZone("Asia/Jakarta");
  const [startDate, setStartDate] = useState<DateTime>(now.minus({ days: 7 }));
  const [endDate, setEndDate] = useState<DateTime>(now.startOf("day"));
  const [isClean, setIsClean] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { accessToken } = useAuth();

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!accessToken) return;
    if (!isClean) return;

    try {
      showLoading("serial-number.export-csv");
      setIsLoading(true);

      const netInstance = net(accessToken);

      const relUrl = `/serial-numbers/export-data/csv`;
      const query = new URLSearchParams();
      query.append("warranty_start_date", startDate.toISO());
      query.append("warranty_end_date", endDate.toISO());

      const res = await netInstance.get(`${relUrl}?${query.toString()}`);
      const csvUrl = lodash(res).get("data.url");

      const strStartDate = startDate.toFormat("ddMMyyyy");
      const strEndDate = endDate.toFormat("ddMMyyyy");
      const fileName = `data_${strStartDate}_${strEndDate}`;
      download(csvUrl, fileName, ".csv");

      context.closeModal(id);
      loadingComplete("serial-number.export-csv", false);
    } catch (err) {
      loadingComplete("serial-number.export-csv", true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const diffInMonths = endDate.diff(startDate, "months").months;
    setIsClean(
      startDate !== undefined &&
        startDate !== null &&
        endDate !== undefined &&
        endDate !== null &&
        startDate < endDate &&
        diffInMonths <= 1
    );
  }, [startDate, endDate]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <Text>
          Only one month of data can be exported at a time. Please select the
          Warranty Start Date and Warranty End Date.
        </Text>
        <DatePickerComponent
          label="Warranty Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <DatePickerComponent
          label="Warranty End Date"
          value={endDate}
          onChange={setEndDate}
        />
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          cancel={{ disabled: isLoading }}
          submit={{ label: "Export CSV", disabled: !isClean || isLoading }}
        />
      </Stack>
    </Box>
  );
}
