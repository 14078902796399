import { Stack } from "@mantine/core";
import { MainContentComponent } from "components";
import { DataProvider } from "./providers";
import { useParams } from "react-router-dom";
import { useDevice } from "hooks";
import {
  SaveChangesButtonComponent,
  DeviceInputComponent,
  HeaderLeft,
  WarrantyInputComponent,
} from "./components";

export function EditPage() {
  const { id } = useParams();
  const { device } = useDevice({ deviceId: id });

  if (!device) return null;
  return (
    <DataProvider device={device}>
      <MainContentComponent
        headerLeft={<HeaderLeft />}
        headerRight={<SaveChangesButtonComponent />}
      >
        <Stack>
          <DeviceInputComponent />
          <WarrantyInputComponent />
        </Stack>
      </MainContentComponent>
    </DataProvider>
  );
}
