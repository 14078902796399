import { Text } from "@mantine/core";
import { TitleComponentProps } from "./types";

export function TitleComponent(props: TitleComponentProps) {
  const { children } = props;

  return (
    <Text
      style={{
        color: "#212121",
        fontSize: 18,
        fontWeight: 700,
        lineHeight: "27px",
      }}
    >
      {children}
    </Text>
  );
}
