import { useTableProvider } from "../../providers";

import { DateTime } from "luxon";
import { TableItemComponent } from "../TableItem";

export function TableItemsComponent() {
  const { data } = useTableProvider();

  return (
    <>
      {data.map((s) => (
        <tr key={s.id}>
          <td>{s.serialNumber?.serialNumber}</td>
          <td>
            {s.serialNumber?.device?.name} <br />
            <small>
              <i>{s.serialNumber?.device?.brand.name}</i>
            </small>
          </td>
          <td>{s.transactionDate.toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>
            <TableItemComponent.Files transaction={s} />
          </td>
          <td>{s.updatedAt.toLocaleString(DateTime.DATETIME_MED)}</td>
        </tr>
      ))}
    </>
  );
}
