import { useStyles } from "./styles";

import { TextInput } from "@mantine/core";
import { TextInputComponentProps } from "./types/TextInput";

export function TextInputComponent(props: TextInputComponentProps) {
  const { classes } = useStyles();
  const {
    required,
    placeholder,
    value,
    disabled,
    radius,
    rightSection,
    rightSectionWidth,
    onChange,
  } = props;

  return (
    <TextInput
      placeholder={placeholder ?? "Type here..."}
      classNames={classes}
      required={required}
      value={value}
      onChange={onChange}
      disabled={disabled}
      rightSectionWidth={rightSectionWidth}
      rightSection={rightSection}
      radius={radius ?? 12}
    />
  );
}
