import { Stack, Text } from "@mantine/core";
import { CourierComponentProps } from "./types";

export function CourierComponent(props: CourierComponentProps) {
  const { courier, service } = props;

  return (
    <Stack spacing={0}>
      <Text>{courier.name}</Text>
      <Text size="sm">
        <i>{service.name}</i>
      </Text>
    </Stack>
  );
}
