import { useSpareParts } from "hooks";

import { TableProvider } from "./provider";
import { MainContentComponent, TableComponent } from "components";
import {
  CreateSparePartButtonComponent,
  HeaderLeft,
  TableItems,
  TablePaginationComponent,
} from "./components";

export function SparePartPage() {
  const { isLoading } = useSpareParts();

  return (
    <TableProvider>
      <MainContentComponent
        headerLeft={<HeaderLeft />}
        headerRight={<CreateSparePartButtonComponent />}
      >
        <TableComponent>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Created At</th>
              <th></th>
            </tr>
          </thead>
          <TableComponent.Body colCount={4} isLoading={isLoading}>
            <TableItems />
          </TableComponent.Body>
          <TableComponent.Pagination>
            <TablePaginationComponent />
          </TableComponent.Pagination>
        </TableComponent>
      </MainContentComponent>
    </TableProvider>
  );
}
