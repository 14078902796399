import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme, props, getRef) => ({
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  input: {
    border: "1px solid #E7E6EA",
    borderRadius: 6,
    color: "#212121",
    fontSize: 14,
    fontWeight: 400,
    height: 32,
    lineHeight: "21px",
    paddingTop: 4,
    paddingBottom: 4,
  },
  label: {
    color: "#212121",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "27px",
  },
  button: {
    border: "1px solid #E7E6EA",
    borderRadius: 6,
    color: "#212121",
    fontSize: 14,
    fontWeight: 400,
    height: 32,
    lineHeight: "21px",
    paddingTop: 4,
    paddingBottom: 4,
  },
  svg: { display: "flex" },
}));
