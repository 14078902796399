import { DispatchAction, FormData } from "./types";

export const reducer = (state: FormData, action: DispatchAction) => {
  switch (action.type) {
    case "SET_DATA":
      return Object.assign({}, state, { [action.key]: action.value });
    case "ADD_ITEM":
      const items = [...state.items, action.item];
      return Object.assign({}, state, { items });
    case "REMOVE_ITEM":
      const { items: i } = state;
      const itemsAfterRemoval = i?.filter((_, index) => index !== action.index);
      return Object.assign({}, state, { items: itemsAfterRemoval });
    case "UPDATE_ITEM":
      const { items: ii } = state;
      const itemsAfterUpdate = ii?.map((item, index) =>
        index === action.index ? action.item : item
      );
      return Object.assign({}, state, { items: itemsAfterUpdate });
    case "UPDATE_CUSTOMER_INFORMATION":
      return Object.assign({}, state, {
        customerInformation: action.value,
      });
    default:
      throw new Error("Not Implemented");
  }
};
