import { Menu } from "@mantine/core";
import { PaymentLinkMenuItemProps } from "./types";
import { useModals } from "@mantine/modals";
import { ModalComponent } from "components";
import { usePaymentLink, usePickupRequestInvoices } from "hooks";

export function PaymentLinkMenuItem(props: PaymentLinkMenuItemProps) {
  const { pickupRequest } = props;
  const { invoices } = usePickupRequestInvoices({
    pickupRequestId: pickupRequest.id,
  });

  const { paymentLink } = usePaymentLink({
    paymentLinkId: invoices[0]?.paymentLinkId,
  });

  const modal = useModals();

  function handleClick() {
    if (!paymentLink) return;
    modal.openContextModal("payment-link.view", {
      title: <ModalComponent.Title>Payment Link</ModalComponent.Title>,
      innerProps: { paymentLink },
    });
  }

  if (!paymentLink) return <></>;
  return <Menu.Item onClick={handleClick}>Show Payment Link</Menu.Item>;
}
