import { SelectComponent } from "components/Select";
import { BrandModel } from "models";
import { BrandSelectInputProps } from "./types/Brand";
import { useBrands } from "hooks";

export function BrandSelectInput(props: BrandSelectInputProps) {
  const { value, onChange } = props;
  const { brands } = useBrands({ limit: 99999 });

  function toData(sp: BrandModel[]) {
    return sp.map((s) => ({
      label: s.name,
      value: s.id,
    }));
  }

  function handleChange(brandId: string) {
    const brand = brands.find((s) => s.id === brandId);
    if (onChange && brand) onChange(brand);
  }

  return (
    <SelectComponent
      label="Brand"
      data={toData(brands)}
      onChange={handleChange}
      value={value?.id}
    />
  );
}
