import { usePickupRequest } from "hooks";
import { createContext, useContext, useEffect, useState } from "react";
import { StepContextProps, StepMapping, StepProviderProps } from "./types";

const StepContext = createContext<StepContextProps>({
  currentStep: 0,
});

export function StepProvider(props: StepProviderProps) {
  const { children, pickupRequestId } = props;
  const { pickupRequest } = usePickupRequest({ id: pickupRequestId });

  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    const stepMapping: StepMapping = {
      pending: 0,
      created: 1,
      received: 2,
    };

    const currentStep = stepMapping[pickupRequest?.status || ""];
    setCurrentStep(currentStep);
  }, [pickupRequest?.status]);

  return (
    <StepContext.Provider value={{ currentStep }}>
      {children}
    </StepContext.Provider>
  );
}

export function useStepProvider() {
  return useContext(StepContext);
}
