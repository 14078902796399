import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
  label: {
    color: "#212121",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "27px",
  },
}));
