import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme, _params, getRef) => ({
  fullHeight: {
    height: "100%"
  },
  paper: {
    margin: "0 80px 80px 80px",
    maxWidth: 920,
    width: "100%"
  },
  title: {
    fontFamily: "Playfair Display"
  }
}));

export default useStyles;