import { useTableProvider } from "../../provider";

import { MainContentComponent, TableComponent } from "components";
import {
  HeaderLeft,
  TableItems,
  TablePaginationComponent,
  HeaderRight,
} from "..";

export function MainComponent() {
  const { isLoading } = useTableProvider();

  return (
    <MainContentComponent
      headerLeft={<HeaderLeft />}
      headerRight={<HeaderRight />}
    >
      <TableComponent>
        <thead>
          <tr>
            <th>Device</th>
            <th>Brand</th>
            <th>Created At</th>
            <th></th>
          </tr>
        </thead>
        <TableComponent.Body colCount={4} isLoading={isLoading}>
          <TableItems />
        </TableComponent.Body>
        <TableComponent.Pagination>
          <TablePaginationComponent />
        </TableComponent.Pagination>
      </TableComponent>
    </MainContentComponent>
  );
}
