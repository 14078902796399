import { Badge } from "@mantine/core";
import { useCallback } from "react";
import { PaymentStatusBadgeComponentProps } from "./types";

export function PaymentStatusBadgeComponent(
  props: PaymentStatusBadgeComponentProps
) {
  const { paymentLink } = props;

  const generateColor = useCallback(() => {
    if (!paymentLink) return "gray";

    if (paymentLink.status === "pending") return "yellow";
    else if (paymentLink.status === "paid") return "green";
    else return "red";
  }, [paymentLink]);

  const generateLabel = useCallback(() => {
    if (!paymentLink) return "Loading";

    if (paymentLink.status === "pending") return "Pending";
    else if (paymentLink.status === "paid") return "Paid";
    else return "Unknown";
  }, [paymentLink]);

  return <Badge color={generateColor()}>{generateLabel()}</Badge>;
}
