import React from "react";
import validator from "validator";
import { CreateSerialNumberModalProps } from "./types";
import { net } from "utils/net";
import { loadingComplete, showLoading } from "utils/loader";

import { Box, Stack } from "@mantine/core";
import {
  DatePickerComponent,
  FormInput,
  ModalComponent,
  TextInputComponent,
} from "components";

import { useState, useEffect } from "react";
import { useAuth } from "providers/auth";
import { DateTime } from "luxon";
import { DeviceModel } from "models";

export function CreateSerialNumberModal(props: CreateSerialNumberModalProps) {
  const { context, id, innerProps } = props;
  const { onSubmit } = innerProps;
  const { accessToken } = useAuth();

  const [serialNumber, setSerialNumber] = useState<string>("");
  const [purchaseDate, setPurchaseDate] = useState<DateTime>(DateTime.now());
  const [device, setDevice] = useState<DeviceModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClean, setIsClean] = useState<boolean>(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!isClean) return;
    if (!accessToken) return;
    if (!device) return;

    try {
      showLoading("serial-number.create");
      setIsLoading(true);

      const relativeUrl = `/serial-numbers`;
      const body = {
        serial_number: serialNumber,
        purchase_date: purchaseDate.toISO(),
        device_id: device.id,
      };

      const netInstance = net(accessToken);
      await netInstance.post(relativeUrl, body);
      if (onSubmit) await onSubmit();

      loadingComplete("serial-number.create", false);
      context.closeModal(id);
    } catch (err) {
      loadingComplete("serial-number.create", true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleSerialNumberChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSerialNumber(e.target.value);
  }

  useEffect(() => {
    setIsClean(!validator.isEmpty(serialNumber) && device !== undefined);
  }, [serialNumber, device]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <TextInputComponent
          label="Serial Number"
          value={serialNumber}
          onChange={handleSerialNumberChange}
        />
        <DatePickerComponent
          label="Purchase Date"
          value={purchaseDate}
          onChange={setPurchaseDate}
        />
        <FormInput.Device value={device} onChange={setDevice} />

        <ModalComponent.ActionButtons
          context={context}
          id={id}
          submit={{ label: "Add New", disabled: isLoading || !isClean }}
        />
      </Stack>
    </Box>
  );
}
