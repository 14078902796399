import { LoadingRowComponent } from "..";
import { LoadingGroupComponentProps } from "./types";

export function LoadingGroupComponent(props: LoadingGroupComponentProps) {
  const { colCount } = props;

  return (
    <>
      <LoadingRowComponent colCount={colCount} />
      <LoadingRowComponent colCount={colCount} />
      <LoadingRowComponent colCount={colCount} />
      <LoadingRowComponent colCount={colCount} />
      <LoadingRowComponent colCount={colCount} />
    </>
  );
}
