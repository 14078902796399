import { DateTime } from "luxon";
import { Properties, Constructor } from "./types";

export interface DestinationLocationModel extends Properties {}
export class DestinationLocationModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    return new DestinationLocationModel({
      id: res.id,
      country: res.country,
      province: res.province,
      city: res.city,
      district: res.district,
      subdistrict: res.subdistrict,
      postalCode: res.postal_code,
      code: res.code,
      createdAt: DateTime.fromISO(res.created_at),
      updatedAt: DateTime.fromISO(res.updated_at),
      deletedAt: res.deleted_at && DateTime.fromISO(res.deleted_at),
    });
  }
}
