import { FC } from "react";
import { Box, Skeleton, TextInput } from "@mantine/core";
import useStyles from "./styles";
import { Props } from "./types";

const MazutaTextInput: FC<Props> = ({ props, ...other }: Props) => {
  const { classes, cx } = useStyles({
    mainStyle: props.mainStyle ?? "normal"
  });

  return (
    <Skeleton visible={props.isLoad ?? false}>
      <TextInput
        {...other}
        classNames={{
          input: cx(classes.input, props.inputStyle),
          label: cx(classes.label, props.labelStyle)
        }}
        {...(props.condition)
          ? ((props.condition === "required")
            ? { required: true }
            : { disabled: true }
          ): {}
        }
        {...(props.description) ? { description: props.description } : {}}
        {...(props.icon)
          ? {
            icon: (
              <Box className={classes.flexCenter} ml={8}>
                {props.icon}
              </Box>
            )
          }
          : {}
        }
        {...(props.label) ? { label: props.label } : {}}
        placeholder={props.placeholder}
        radius={props.radius ?? 12}
        {...(props.rightSection)
          ? {
            rightSection: (
              <Box className={classes.flexCenter} ml={8}>
                {props.rightSection}
              </Box>
            )
          }
          : {}
        }
      />
    </Skeleton>    
  );
}

export default MazutaTextInput;