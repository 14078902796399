import { Stack, Text, Title } from "@mantine/core";
import { usePickupRequest } from "hooks";
import { useParams } from "react-router";

export function HeaderLeft() {
  const { id } = useParams();
  const { pickupRequest } = usePickupRequest({ id });

  return (
    <Stack spacing={0}>
      <Title>Pickup Request Detail</Title>
      <Text size="sm" color="dimmed">
        {pickupRequest?.id}
      </Text>
    </Stack>
  );
}
