import { Badge } from "@mantine/core";
import { InDirectionComponentProps } from "./types";

export function InDirectionComponent(props: InDirectionComponentProps) {
  const { directions } = props;

  if (directions.includes("in")) {
    return (
      <Badge color="blue" mr="xs">
        IN
      </Badge>
    );
  } else return <></>;
}
