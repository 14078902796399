import { Stack } from "@mantine/core";
import { MainContentComponent } from "components";
import {
  AddNewButtonComponent,
  DeviceInputComponent,
  HeaderLeft,
  WarrantyInputComponent,
} from "./components";
import { DataProvider } from "./providers";

export function AddNewPage() {
  return (
    <DataProvider>
      <MainContentComponent
        headerLeft={<HeaderLeft />}
        headerRight={<AddNewButtonComponent />}
      >
        <Stack>
          <DeviceInputComponent />
          <WarrantyInputComponent />
        </Stack>
      </MainContentComponent>
    </DataProvider>
  );
}
