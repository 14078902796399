import { Popover, Space, Stack } from "@mantine/core";
import { ButtonComponent, DatePickerComponent } from "components";
import { DateTime } from "luxon";
import { useTableProvider } from "pages/SerialNumberPage/providers";
import { useState } from "react";

export function FilterButtonComponent() {
  const { setFilter } = useTableProvider();
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [warStartDate, setWarStartDate] = useState<DateTime>(
    DateTime.now().setZone("Asia/Jakarta").minus({ days: 7 })
  );

  const [warEndDate, setWarEndDate] = useState<DateTime>(
    DateTime.now().setZone("Asia/Jakarta")
  );

  function toggleFilter() {
    setPopoverOpen((prev) => !prev);
  }

  function handleClick() {
    setFilter({
      warrantyStartDate: warStartDate,
      warrantyEndDate: warEndDate,
    });
    toggleFilter();
  }

  return (
    <Popover
      opened={popoverOpen}
      target={
        <ButtonComponent
          onClick={toggleFilter}
          mainStyle="medium"
          variant="outline"
        >
          Filter
        </ButtonComponent>
      }
      withArrow
    >
      <div style={{ display: "flex" }}>
        <Stack>
          <DatePickerComponent
            value={warStartDate}
            onChange={setWarStartDate}
            label="Warranty Start Date"
          />
          <DatePickerComponent
            value={warEndDate}
            onChange={setWarEndDate}
            label="Warranty End Date"
          />
          <Space />
          <ButtonComponent onClick={handleClick} mainStyle="medium">
            Apply Filter
          </ButtonComponent>
        </Stack>
      </div>
    </Popover>
  );
}
