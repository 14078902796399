import { FC, useEffect } from "react";
import { Center, Paper, ScrollArea, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "providers/auth";
import useStyles from "./styles";
import { ButtonComponent } from "components";
import PasswordInputComponent from "components/PasswordInput";
import TextComponent from "components/Text";
import TextInputComponent from "components/TextInput.old";
import HeaderComponent from "./components/Header";
import TemplateComponent from "./components/Template";
import FormData from "./form";
import { TFormSchema } from "./form";

const LoginPage: FC = () => {
  const { classes } = useStyles();
  const { accessToken, isFetching, login } = useAuth();
  const navigate = useNavigate();
  const { formInit } = FormData;
  const form = useForm<TFormSchema>(formInit);

  function doSubmit(values: TFormSchema) {
    login(values.email, values.password);
  }

  useEffect(() => {
    if (accessToken) navigate("/dashboard", { replace: true });
  }, [accessToken, navigate]);

  return (
    <TemplateComponent>
      <ScrollArea className={classes.fullHeight}>
        <HeaderComponent />
        <Center>
          <Paper
            className={classes.paper}
            component="form"
            onSubmit={form.onSubmit(doSubmit)}
            radius={24}
            shadow="xs"
          >
            <Stack my={40} mx={120}>
              <TextComponent
                rootStyle={classes.title}
                lineHeight={67}
                size={54}
                weight={700}
                align="center"
                value="Welcome Back!"
              />
              <TextComponent
                dimmed
                lineHeight={28}
                size={18}
                weight={500}
                align="center"
                value="Please enter your details to login"
              />
              <TextInputComponent
                props={{
                  label: "Email",
                  placeholder: "enter your email",
                  mainStyle: "light",
                }}
                {...form.getInputProps("email")}
              />
              <PasswordInputComponent
                props={{
                  condition: "required",
                  label: "Password",
                  placeholder: "enter your password",
                  mainStyle: "light",
                }}
                {...form.getInputProps("password")}
              />
              {/* <Group position="apart" mb="sm">
                <Checkbox
                  label={
                    <TextComponent
                      lineHeight={30}
                      size={20}
                      weight={400}
                      value="Remember Me"
                    />
                  }
                  onClick={handleRememberMeClick}
                />
                <ButtonComponent
                  variant="subtle"
                  color="dark"
                  onClick={handleForgetPasswordClick}
                >
                  <TextComponent
                    lineHeight={30}
                    size={20}
                    weight={400}
                    value="Forget Password?"
                  />
                </ButtonComponent>
              </Group> */}
              <ButtonComponent
                disabled={isFetching}
                type="submit"
                isFetching={isFetching}
              >
                Login
              </ButtonComponent>
            </Stack>
          </Paper>
        </Center>
      </ScrollArea>
    </TemplateComponent>
  );
};

export default LoginPage;
