import { Card, Stack } from "@mantine/core";
import { CardDataDisplayComponent } from "components";
import { usePickupRequest } from "hooks";
import { useParams } from "react-router";
import { SectionTitleComponent } from "..";

export function SenderInformationSection() {
  const { id } = useParams();
  const { pickupRequest } = usePickupRequest({ id });

  return (
    <Card>
      <Stack>
        <SectionTitleComponent>Sender Information</SectionTitleComponent>
        <CardDataDisplayComponent
          label="Name"
          value={pickupRequest?.senderInformation.name}
        />
        <CardDataDisplayComponent
          label="Phone Number"
          value={`+62${pickupRequest?.senderInformation.phoneNumber}`}
        />
        <CardDataDisplayComponent
          label="Pickup Location"
          value={pickupRequest?.senderInformation.pickupLocation.name}
        />
        <CardDataDisplayComponent
          label="Address"
          value={pickupRequest?.senderInformation.address}
        />
      </Stack>
    </Card>
  );
}
