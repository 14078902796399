import { useModals } from "@mantine/modals";
import { useTableProvider } from "../../provider";

import { ButtonComponent, ModalComponent } from "components";
import { Plus } from "tabler-icons-react";

export function CreateSparePartButtonComponent() {
  const modals = useModals();
  const { refreshTable } = useTableProvider();

  function handleClick() {
    modals.openContextModal("spare-part.create", {
      title: <ModalComponent.Title>Add New Spare Part</ModalComponent.Title>,
      innerProps: {
        onSubmit: async () => await refreshTable(),
      },
    });
  }

  return (
    <ButtonComponent onClick={handleClick} leftIcon={<Plus />}>
      Add New Spare Part
    </ButtonComponent>
  );
}
