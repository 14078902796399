import { DeviceModel } from "models";
import { MergeDevicesModalProps } from "./types";
import { useEffect, useState } from "react";

import { Box, Space, Stack, Text } from "@mantine/core";
import { FormInput, ModalComponent } from "components";
import { useAuth } from "providers/auth";
import { loadingComplete, showLoading } from "utils/loader";
import { net } from "utils/net";

export function MergeDevicesModal(props: MergeDevicesModalProps) {
  const { context, id, innerProps } = props;
  const { onSubmit } = innerProps;
  const { accessToken } = useAuth();

  const [sourceDevice, setSourceDevice] = useState<DeviceModel>();
  const [targetDevice, setTargetDevice] = useState<DeviceModel>();
  const [isClean, setIsClean] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!isClean) return;
    if (!accessToken) return;
    if (!sourceDevice) return;
    if (!targetDevice) return;

    try {
      showLoading("device.merge");
      setIsLoading(true);

      const relativeUrl = `/scripts/merge-device/execute`;
      const body = {
        source_device_id: sourceDevice.id,
        target_device_id: targetDevice.id,
      };

      const netInstance = net(accessToken);
      await netInstance.post(relativeUrl, body);
      if (onSubmit) await onSubmit();

      loadingComplete("device.merge", false);
      context.closeModal(id);
    } catch (err) {
      loadingComplete("device.merge", true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsClean(!!sourceDevice && !!targetDevice);
  }, [sourceDevice, targetDevice]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <Text>
          This function will merge source device into target device. Please note
          that the merging is final and cannot be undo.
        </Text>
        <Text>
          The source device will be deleted from database, and all serial number
          that are associated with source device will be changed to target
          device.
        </Text>
        <FormInput.Device
          label="Source Device"
          value={sourceDevice}
          onChange={setSourceDevice}
        />
        <FormInput.Device
          label="Target Device"
          value={targetDevice}
          onChange={setTargetDevice}
        />
        <Space />
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          submit={{ label: "Merge Devices", disabled: isLoading || !isClean }}
        />
      </Stack>
    </Box>
  );
}
