import { UseDeviceProps } from "./types";
import { DeviceModel } from "models";
import { fetcher } from "utils/net";

import useSWR from "swr";
import { useAuth } from "providers/auth";
import { useEffect, useState } from "react";

export function useDevice(props: UseDeviceProps) {
  const [device, setDevice] = useState<DeviceModel>();

  const { deviceId } = props;
  const { accessToken } = useAuth();
  const { data, error, mutate } = useSWR(() => {
    if (!accessToken) return undefined;
    if (!deviceId) return undefined;

    return [`/devices/${deviceId}`, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) return;

    const device = DeviceModel.fromResponse(data);
    setDevice(device);
  }, [data]);

  return {
    device: device,
    error: error,
    isLoading: !device && !error,
    mutate,
  };
}
