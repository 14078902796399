import { useCallback } from "react";
import CurrencyUtils from "utils/currency";
import { CurrencyComponentProps } from "./types";

export function CurrencyComponent(props: CurrencyComponentProps) {
  const { value } = props;

  const getLabel = useCallback(() => {
    return CurrencyUtils.toCurrency(value);
  }, [value]);

  return <span>{getLabel()}</span>;
}
