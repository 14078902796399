import { SelectComponent } from "components/Select";
import { DeviceModel } from "models";
import { DeviceSelectInputProps } from "./types/Device";
import { useDevices } from "hooks";

export function DeviceSelectInput(props: DeviceSelectInputProps) {
  const { disabled, value, label, onChange } = props;
  const { devices } = useDevices({ limit: 99999 });

  function toData(sp: DeviceModel[]) {
    return sp.map((s) => ({
      label: s.name,
      value: s.id,
    }));
  }

  function handleChange(deviceId: string) {
    const device = devices.find((s) => s.id === deviceId);
    if (onChange && device) onChange(device);
  }

  return (
    <SelectComponent
      label={label || "Device"}
      data={toData(devices)}
      onChange={handleChange}
      value={value?.id}
      disabled={disabled}
    />
  );
}
