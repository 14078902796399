import { DateTime } from "luxon";
import { Properties, Constructor } from "./types";

export interface SparePartModel extends Properties {}
export class SparePartModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(response: any): SparePartModel {
    return new SparePartModel({
      id: response.id,
      name: response.name,
      createdAt: DateTime.fromISO(response.created_at),
      updatedAt: DateTime.fromISO(response.updated_at),
      deletedAt: response.deleted_at && DateTime.fromISO(response.deleted_at),
    });
  }
}
