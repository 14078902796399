import lodash from "lodash";

import { Skeleton } from "@mantine/core";
import { LoadingRowComponentProps } from "./types";

export function LoadingRowComponent(props: LoadingRowComponentProps) {
  const { colCount } = props;

  return (
    <tr>
      {lodash(Array(colCount))
        .fill(0)
        .map((_, index) => {
          return (
            <td key={`loading-row-component.${index}`}>
              <Skeleton height={8} mt="xs" radius="xl" />
            </td>
          );
        })
        .value()}
    </tr>
  );
}
