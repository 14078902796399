import { DateTime } from "luxon";
import { SerialNumberModel } from "models/serial-number";
import { Properties, Constructor } from "./types";

export interface WarehouseTransactionModel extends Properties {}
export class WarehouseTransactionModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    return new WarehouseTransactionModel({
      id: res.id,
      serialNumberId: res.serial_number_id,
      serialNumber:
        res.serial_number && SerialNumberModel.fromResponse(res.serial_number),
      directions: res.directions,
      transactionDate: DateTime.fromISO(res.transaction_date),
      createdAt: DateTime.fromISO(res.created_at),
      updatedAt: DateTime.fromISO(res.updated_at),
      deletedAt: res.deleted_at && DateTime.fromISO(res.deleted_at),
    });
  }
}
