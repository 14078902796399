import { Text, Stack } from "@mantine/core";
import { CardDataDisplayComponentProps } from "./types";

export function CardDataDisplayComponent(props: CardDataDisplayComponentProps) {
  const { label, value } = props;

  return (
    <Stack spacing={0}>
      <Text size="sm" color="dimmed">
        {label}
      </Text>
      <Text>{value ?? "-"}</Text>
    </Stack>
  );
}
