import { useStyles } from "./styles";
import { Group, ScrollArea, Stack } from "@mantine/core";
import { PositionProps, Props } from "./types";

export function MainContentComponent(props: Props) {
  const { classes } = useStyles();
  const { headerLeft, headerRight, children, onScrollPositionChange } = props;

  function handleScrollPositionChange(position: PositionProps) {
    if (onScrollPositionChange) onScrollPositionChange(position);
  }

  return (
    <Stack>
      <ScrollArea
        type="scroll"
        classNames={{ root: classes.root, scrollbar: classes.scrollbar }}
        onScrollPositionChange={handleScrollPositionChange}
        offsetScrollbars
      >
        <Group mb="lg" position="apart">
          {headerLeft ? headerLeft : null}
          {headerRight ? headerRight : null}
        </Group>
        {children}
      </ScrollArea>
    </Stack>
  );
}
