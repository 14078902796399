import { Menu } from "@mantine/core";
import { DateTime } from "luxon";
import { useTableProvider } from "../../providers";
import { TableMenu } from "../TableMenu";

export function TableItems() {
  const { data } = useTableProvider();

  return (
    <>
      {data.map((s) => (
        <tr key={s.id}>
          <td>{s.serialNumber}</td>
          <td>{s.device?.name}</td>
          <td>{s.device?.brand.name}</td>
          <td>{s.warrantyStartDate.toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>{s.updatedAt.toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>
            <Menu>
              <TableMenu.Edit serialNumber={s} />
              <TableMenu.Delete serialNumber={s} />
            </Menu>
          </td>
        </tr>
      ))}
    </>
  );
}
