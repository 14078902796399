import { useTableProvider } from "../../providers";
import { useState } from "react";

import { Button, Popover, Space, Stack } from "@mantine/core";
import { DatePickerComponent } from "components";
import { DateTime } from "luxon";

export function FilterButtonComponent() {
  const { setFilter } = useTableProvider();
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [trxStartDate, setTrxStartDate] = useState<DateTime>(
    DateTime.now().setZone("Asia/Jakarta").minus({ days: 7 })
  );

  const [trxEndDate, setTrxEndDate] = useState<DateTime>(
    DateTime.now().setZone("Asia/Jakarta")
  );

  function toggleFilter() {
    setPopoverOpen((prev) => !prev);
  }

  function handleClick() {
    setFilter({
      transactionStartDate: trxStartDate,
      transactionEndDate: trxEndDate,
    });
    toggleFilter();
  }

  return (
    <Popover
      opened={popoverOpen}
      target={
        <Button onClick={toggleFilter} variant="outline">
          Filter
        </Button>
      }
      withArrow
    >
      <div style={{ display: "flex" }}>
        <Stack>
          <DatePickerComponent
            value={trxStartDate}
            onChange={setTrxStartDate}
            label="Warranty Start Date"
          />
          <DatePickerComponent
            value={trxEndDate}
            onChange={setTrxEndDate}
            label="Warranty End Date"
          />
          <Space />
          <Button onClick={handleClick}>Apply Filter</Button>
        </Stack>
      </div>
    </Popover>
  );
}
