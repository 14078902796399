import { useModals } from "@mantine/modals";
import { ButtonComponent, ModalComponent } from "components";
import { WarrantySubmitProps } from "./types/AddWarrantyButton";
import { useDataProvider } from "../../providers";

export function AddWarrantyButtonComponent() {
  const modals = useModals();
  const { dispatch } = useDataProvider();

  function handleWarrantySubmit(props: WarrantySubmitProps) {
    dispatch({
      type: "ADD_WARRANTY",
      part: props.part,
      period: props.period,
    });
  }

  function handleClick() {
    modals.openContextModal("warranty.select", {
      title: (
        <ModalComponent.Title>Select Warranty Coverage</ModalComponent.Title>
      ),
      innerProps: {
        onSubmit: handleWarrantySubmit,
      },
    });
  }

  return (
    <ButtonComponent variant="outline" mainStyle="small" onClick={handleClick}>
      Add Warranty Coverage
    </ButtonComponent>
  );
}
