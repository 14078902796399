import { useMantineTheme } from "@mantine/core";

import { Box, Navbar } from "@mantine/core";
import { FooterSectionComponentProps } from "./types";

export function FooterSectionComponent(props: FooterSectionComponentProps) {
  const { children } = props;
  const theme = useMantineTheme();

  return (
    <Navbar.Section>
      <Box
        sx={{
          paddingTop: theme.spacing.sm,
          borderTop: `1px solid ${theme.colors.gray[2]}`,
        }}
      >
        {children}
      </Box>
    </Navbar.Section>
  );
}
