import { Box, Stack } from "@mantine/core";
import { useSerialNumber, useSerialNumberDevice } from "hooks";
import { SerialNumberComponentProps } from "./types";

export function SerialNumberComponent(props: SerialNumberComponentProps) {
  const { invoice } = props;
  const { serialNumber } = useSerialNumber({
    serialNumberId: invoice.serialNumberId,
  });

  const { device } = useSerialNumberDevice({
    serialNumberId: invoice.serialNumberId,
  });

  if (!serialNumber) return <></>;
  if (!device) return <></>;
  return (
    <Stack spacing={0}>
      <Box>{serialNumber.serialNumber}</Box>
      <Box>
        <i>{device.brand.name}</i>
      </Box>
      <Box>
        <i>{device.name}</i>
      </Box>
    </Stack>
  );
}
