import { Menu } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { ModalComponent } from "components";
import { useTableProvider } from "pages/DevicePage/pages/List/provider";

export function MergeDevicesMenuItem() {
  const modals = useModals();
  const { refreshTable } = useTableProvider();

  function handleClick() {
    modals.openContextModal("device.merge", {
      title: <ModalComponent.Title>Merge Devices</ModalComponent.Title>,
      innerProps: {
        onSubmit: async () => await refreshTable(),
      },
    });
  }

  return <Menu.Item onClick={handleClick}>Merge Devices</Menu.Item>;
}
