import {
  CustomerNameComponent,
  InvoiceTypeComponent,
  PhoneNumberComponent,
  TotalComponent,
  SerialNumberComponent,
  DeviceInformationComponent,
} from "./components";

export function Metadata() {
  return <></>;
}

Metadata.Total = TotalComponent;
Metadata.CustomerName = CustomerNameComponent;
Metadata.PhoneNumber = PhoneNumberComponent;
Metadata.InvoiceType = InvoiceTypeComponent;
Metadata.SerialNumber = SerialNumberComponent;
Metadata.DeviceInformation = DeviceInformationComponent;
