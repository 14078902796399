import { BrandSelectInput } from "./Brand";
import { DeviceSelectInput } from "./Device";
import { SparePartSelectInput } from "./SparePart";
import { PhoneNumberInputComponent } from "./phone-number";

export function FormInput() {
  return <></>;
}

FormInput.SparePart = SparePartSelectInput;
FormInput.Brand = BrandSelectInput;
FormInput.Device = DeviceSelectInput;
FormInput.PhoneNumber = PhoneNumberInputComponent;
