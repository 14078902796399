import { InputLabelComponentProps } from "./types";
import { Text } from "@mantine/core";
import { useStyles } from "./styles";

export function InputLabelComponent(props: InputLabelComponentProps) {
  const { classes } = useStyles();
  const { children } = props;

  return <Text className={classes.label}>{children}</Text>;
}
