import React from "react";
import validator from "validator";
import lodash from "lodash";
import { CreatePickupRequestModalProps } from "./types";
import { net } from "utils/net";

import { Box, Stack, Text } from "@mantine/core";
import { loadingComplete, showLoading } from "utils/loader";
import { ModalComponent, TextInputComponent } from "components";

import { useState, useEffect } from "react";
import { usePickupRequests, useSerialNumber } from "hooks";
import { useAuth } from "providers/auth";

export function CreatePickupRequestModal(props: CreatePickupRequestModalProps) {
  const { context, id, innerProps } = props;
  const { onSubmit } = innerProps;
  const { mutate } = usePickupRequests();
  const { accessToken } = useAuth();

  const [serialNumber, setSerialNumber] = useState<string>("");
  const [discountPercent, setDiscountPercent] = useState<string>("");
  const [discountValue, setDiscountValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClean, setIsClean] = useState<boolean>(false);

  // This hook is used to get the serial number from the server
  const { serialNumber: fSerialNumber } = useSerialNumber({ serialNumber });

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!fSerialNumber) return;
    if (!accessToken) return;

    try {
      setIsLoading(true);
      showLoading("pickup-request.create");

      // We will insert the pickup request to the database
      const body = lodash({
        serial_number_id: fSerialNumber.id,
        delivery_discount_percent: validator.isEmpty(discountPercent)
          ? 0
          : parseInt(discountPercent),
        delivery_discount_value: validator.isEmpty(discountValue)
          ? 0
          : parseInt(discountValue),
      })
        .omitBy(lodash.isNil)
        .value();

      const netInstance = net(accessToken);
      await netInstance.post(`/pickup-requests`, body);
      await mutate();
      if (onSubmit) await onSubmit();

      context.closeModal(id);
      loadingComplete("pickup-request.create", false);
    } catch (err) {
      loadingComplete("pickup-request.create", true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleSerialNumberChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSerialNumber(e.target.value);
  }

  function isNumeric(value: string) {
    // Check if the last character is a number. If it is a number, then return the whole value
    // If it is not, then we need to return the number only
    const lastChar = value[value.length - 1];
    const isLastCharANumber = !isNaN(parseInt(lastChar));
    return isLastCharANumber ? value : value.substring(0, value.length - 1);
  }

  function handleDiscountPercentChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = isNumeric(e.target.value);
    // We don't accept values greater than 100
    if (parseInt(value) > 100) return;
    setDiscountPercent(value);
  }

  function handleDiscountValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = isNumeric(e.target.value);
    setDiscountValue(value);
  }

  useEffect(() => {
    setIsClean(!validator.isEmpty(serialNumber) && fSerialNumber !== undefined);
  }, [serialNumber, fSerialNumber]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <TextInputComponent
          label="Serial Number"
          value={serialNumber}
          onChange={handleSerialNumberChange}
        />
        <TextInputComponent
          label="Serial Number ID"
          placeholder=""
          value={fSerialNumber?.id ?? ""}
          disabled
        />
        <Text size="lg" weight={600}>
          Delivery Discount
        </Text>
        <TextInputComponent
          label="Discount Percent"
          value={discountPercent}
          onChange={handleDiscountPercentChange}
        />
        <TextInputComponent
          label="Discount Value"
          value={discountValue}
          onChange={handleDiscountValueChange}
        />
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          submit={{ label: "Create", disabled: isLoading || !isClean }}
        />
      </Stack>
    </Box>
  );
}
