import { PaginationType, TableContextProps, TableProviderProps } from "./types";
import { FilterOptions } from "hooks/pickup-requests/types";
import { createContext } from "react";
import { funcNotImplemented, funcNotImplementedAsync } from "utils/misc";

import { usePickupRequests } from "hooks";
import { useContext, useState } from "react";

const TableContext = createContext<TableContextProps>({
  data: [],
  totalPage: 1,
  isLoading: false,
  setFilter: funcNotImplemented,
  refreshTable: funcNotImplementedAsync,
  changePage: funcNotImplemented,
});

export function TableProvider(props: TableProviderProps) {
  const { children } = props;

  const [page, setPage] = useState<PaginationType>({ limit: 5, offset: 0 });
  const [filter, setFilter] = useState<FilterOptions>({});
  const { pickupRequests, totalCount, itemPerPage, isLoading, mutate } =
    usePickupRequests({
      limit: page.limit,
      offset: page.offset,
      filter: filter,
    });

  function changePage(page: number) {
    setPage({
      limit: 5,
      offset: (page - 1) * 5,
    });
  }

  async function refreshTable() {
    await mutate();
  }

  return (
    <TableContext.Provider
      value={{
        data: pickupRequests,
        isLoading: isLoading,
        totalPage: Math.ceil(totalCount / itemPerPage),
        setFilter: setFilter,
        refreshTable: refreshTable,
        changePage: changePage,
      }}
    >
      {children}
    </TableContext.Provider>
  );
}

export function useTableProvider() {
  return useContext(TableContext);
}
