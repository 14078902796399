import { Menu } from "@mantine/core";
import { EditMenuItemProps } from "./types";
import { ModalComponent } from "components";

import { useModals } from "@mantine/modals";
import { useTableProvider } from "../../provider";

export function EditMenuItem(props: EditMenuItemProps) {
  const { part } = props;

  const modals = useModals();
  const { refreshTable } = useTableProvider();

  function handleClick() {
    modals.openContextModal("spare-part.edit", {
      title: <ModalComponent.Title>Edit Spare Part</ModalComponent.Title>,
      innerProps: {
        part: part,
        onSubmit: async () => await refreshTable(),
      },
    });
  }

  return <Menu.Item onClick={handleClick}>Change Name</Menu.Item>;
}
