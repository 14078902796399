import { useMantineTheme } from "@mantine/core";

import { Box, Burger, Group, Header, MediaQuery } from "@mantine/core";
import { VectorImages } from "components";
import { HeaderComponentProps } from "./types";

export function HeaderComponent(props: HeaderComponentProps) {
  const { opened, toggleOpen } = props;
  const theme = useMantineTheme();

  return (
    <Header height={70} p="md">
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Burger
            opened={opened}
            onClick={toggleOpen}
            size="sm"
            color={theme.colors.gray[6]}
            mr="xl"
          />
        </MediaQuery>

        <Box sx={(theme) => ({ padding: theme.spacing.xs })}>
          <Group position="apart">
            <VectorImages.Logo />
          </Group>
        </Box>
      </div>
    </Header>
  );
}
