import React from "react";
import validator from "validator";
import { net } from "utils/net";
import { BrandEditModalProps } from "./types";
import { loadingComplete, showLoading } from "utils/loader";

import { Box, Stack } from "@mantine/core";
import { TextInputComponent } from "components";
import { ModalComponent } from "components/Modal";

import { useEffect, useState } from "react";
import { useAuth } from "providers/auth";

export function BrandEditModal(props: BrandEditModalProps) {
  const { innerProps, id, context } = props;
  const { brand, onSubmit } = innerProps;

  const [name, setName] = useState<string>(brand.name);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClean, setIsClean] = useState<boolean>(false);
  const { accessToken } = useAuth();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value);
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!isClean) return;
    if (!accessToken) return;

    try {
      setIsLoading(true);
      showLoading("brand.edit");
      const relativeUrl = `/brands/${brand.id}`;
      const body = { name: name };
      const netInstance = net(accessToken);
      await netInstance.patch(relativeUrl, body);

      if (onSubmit) await onSubmit();
      context.closeModal(id);
      loadingComplete("brand.edit", false);
    } catch (err) {
      loadingComplete("brand.edit", true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsClean(!validator.isEmpty(name));
  }, [name]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <TextInputComponent
          label="Brand Name"
          value={name}
          onChange={handleChange}
        />
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          submit={{ label: "Save", disabled: !isClean || isLoading }}
        />
      </Stack>
    </Box>
  );
}
