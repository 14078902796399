import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme, _params, getRef) => ({
  root: {
    marginTop: 8,
    height: "calc(100vh - var(--mantine-header-height, 0px) - 72px)",
  },
  scrollbar: {
    zIndex: 1,
  },
}));
