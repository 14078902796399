import { Properties, Constructor } from "./types";
import { DateTime } from "luxon";

export interface InvoiceModel extends Properties {}
export class InvoiceModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(doc: Record<string, any>) {
    return new InvoiceModel({
      id: doc.id,
      source: doc.source && {
        type: doc.source.type,
        id: doc.source.id,
      },
      serialNumberId: doc.serial_number_id,
      paymentLinkId: doc.payment_link_id && doc.payment_link_id,
      items: doc.items.map((i: Record<string, any>) => ({
        itemId: i.item_id,
        itemDescription: i.item_description,
        qty: i.qty,
        price: i.price,
      })),
      total: doc.total,
      createdAt: DateTime.fromISO(doc.created_at),
      updatedAt: DateTime.fromISO(doc.updated_at),
      deletedAt: doc.deleted_at && DateTime.fromISO(doc.deleted_at),
    });
  }
}
