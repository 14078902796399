import { Menu } from "@mantine/core";
import { DateTime } from "luxon";
import { useTableProvider } from "../../provider";
import { TableMenu } from "../TableMenu";

export function TableItems() {
  const { data } = useTableProvider();

  return (
    <>
      {data.map((d) => (
        <tr key={d.id}>
          <td>{d.name}</td>
          <td>{d.brand.name}</td>
          <td>{d.createdAt.toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>
            <Menu>
              <TableMenu.Edit device={d} />
              <TableMenu.Delete device={d} />
            </Menu>
          </td>
        </tr>
      ))}
    </>
  );
}
