import validator from "validator";
import { DataContextProps, DataProviderProps, FormData } from "./types";
import { createContext } from "react";
import { reducer } from "./reducer";
import { funcNotImplemented } from "utils/misc";
import { useState, useEffect, useContext, useReducer } from "react";
import { useSerialNumber, useSerialNumberDevice } from "hooks";

const initialData: FormData = {
  serialNumber: "",
  items: [],
  sourceType: "ad-hoc",
  customerInformation: {
    name: "",
    phoneNumber: "",
  },
  total: 0,
};

const DataContext = createContext<DataContextProps>({
  data: initialData,
  isClean: false,
  dispatch: funcNotImplemented,
});

export function DataProvider(props: DataProviderProps) {
  const { children } = props;
  const [data, dispatch] = useReducer(reducer, initialData);
  const [isClean, setIsClean] = useState<boolean>(false);

  const { serialNumber: mSerialNumber } = useSerialNumber({
    serialNumber: data.serialNumber,
  });

  const { device } = useSerialNumberDevice({
    serialNumberId: mSerialNumber?.id,
  });

  useEffect(() => {
    setIsClean(
      data.serialNumber !== undefined &&
        device !== undefined &&
        !validator.isEmpty(data.serialNumber) &&
        data.items.length > 0 &&
        data.customerInformation !== undefined &&
        !validator.isEmpty(data.customerInformation.name ?? "") &&
        !validator.isEmpty(data.customerInformation.phoneNumber ?? "")
    );
  }, [data, device]);

  return (
    <DataContext.Provider
      value={{
        data,
        isClean,
        dispatch,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export function useDataProvider() {
  return useContext(DataContext);
}
