import { UsePickupRequestInvoicesProps } from "./types";
import { fetcher } from "utils/net";

import useSWR from "swr";
import { useAuth } from "providers/auth";
import { useState, useEffect } from "react";
import { InvoiceModel } from "models";

export function usePickupRequestInvoices(props: UsePickupRequestInvoicesProps) {
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);

  const { pickupRequestId } = props;
  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!accessToken) return undefined;
    if (!pickupRequestId) return undefined;

    const relUrl = `/pickup-requests/${pickupRequestId}/invoices`;
    return [relUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setInvoices([]);
      return;
    }

    const invoices = data.data.map((d: Record<string, any>) =>
      InvoiceModel.fromResponse(d)
    );

    setInvoices(invoices);
  }, [data]);

  return {
    invoices: invoices,
    error: error,
    isLoading: isLoading,
  };
}
