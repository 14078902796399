import { Menu, Stack, Text } from "@mantine/core";
import { DateTime } from "luxon";
import { MenuItem, TableItemComponent } from "..";
import { useTableProvider } from "../../providers";

export function TableItemsComponent() {
  const { data } = useTableProvider();

  return (
    <>
      {data.map((pr) => (
        <tr key={pr.id}>
          <td>
            <Stack>
              {pr.devices.map((d) => (
                <Stack key={d.serialNumberId} spacing={0}>
                  <Text>{d.serialNumber.serialNumber}</Text>
                  <TableItemComponent.DeviceName
                    serialNumber={d.serialNumber}
                  />
                </Stack>
              ))}
            </Stack>
          </td>
          <td>{pr.senderInformation.name}</td>
          <td>
            <TableItemComponent.Complaint value={pr.complaint ?? ""} />
          </td>
          <td>
            <TableItemComponent.Courier
              courier={pr.courierInformation.courier}
              service={pr.courierInformation.courierService}
            />
          </td>
          <td>
            <TableItemComponent.ServiceCenter value={pr.serviceCenter.name} />
          </td>
          <td>
            <TableItemComponent.PaymentStatus pickupRequest={pr} />
          </td>
          <td>{pr.createdAt.toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>
            <Menu>
              <MenuItem.PaymentLink pickupRequest={pr} />
              <MenuItem.ViewDetail pickupRequest={pr} />
            </Menu>
          </td>
        </tr>
      ))}
    </>
  );
}
