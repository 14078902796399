import { Menu } from "@mantine/core";
import { ModalComponent } from "components";
import { DeleteMenuItemProps } from "./types";

import { useTableProvider } from "../../provider";
import { useModals } from "@mantine/modals";

export function DeleteMenuItem(props: DeleteMenuItemProps) {
  const { device } = props;

  const modals = useModals();
  const { refreshTable } = useTableProvider();

  function handleClick() {
    modals.openContextModal("device.delete", {
      title: <ModalComponent.Title>Are you sure?</ModalComponent.Title>,
      innerProps: {
        device: device,
        onSubmit: async () => await refreshTable(),
      },
    });
  }

  return <Menu.Item onClick={handleClick}>Delete</Menu.Item>;
}
