import { TextInputComponent } from "components";
import { useSerialNumber, useSerialNumberDevice } from "hooks";
import { useDataProvider } from "../../../../providers";

export function DeviceInformationComponent() {
  const { data } = useDataProvider();
  const { serialNumber } = useSerialNumber({ serialNumber: data.serialNumber });
  const { device } = useSerialNumberDevice({
    serialNumberId: serialNumber?.id,
  });

  return (
    <TextInputComponent
      label="Device Information"
      value={device?.name ?? ""}
      disabled
    />
  );
}
