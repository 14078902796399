import { MainComponent } from "./components";
import { TableProvider } from "./providers";

export function SaleTransactionPage() {
  return (
    <TableProvider>
      <MainComponent />
    </TableProvider>
  );
}
