import Config from "configs/api";
import axios from "axios";
import lodash from "lodash";
import { AxiosResponse } from "axios";

export const net = (token: string, headers?: Record<string, string>) => {
  const devHeader = Config.url.includes("localhost")
    ? { "X-Consumer-ID": "mazuta-dev" }
    : {};

  const mergedHeaders = lodash({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  })
    .merge(headers ? headers : {})
    .merge(devHeader)
    .value();

  return axios.create({ baseURL: Config.url, headers: mergedHeaders });
};

/**
 * Return a data from response if no error_text
 * @param res
 * @returns
 */
export const responseChecker = (res: AxiosResponse<any, any>) => {
  const errorText = lodash(res.data).get("error_text", undefined);
  if (errorText === undefined) return res.data;
  else throw new Error(errorText);
};

export const fetcher = async ([pathUrl, token]: [string, string]) => {
  const netInstance = net(token);
  const res = await netInstance.get(pathUrl);
  if (res.status !== 200) throw new Error(res.statusText);

  const errorText = lodash(res.data).get("error_text", undefined);
  if (errorText === undefined) return res.data;
  else throw new Error(errorText);
};

export const download = (url: string, fileName: string, ext: string) => {
  const link = document.createElement("a");
  const fullName = `${fileName}.${ext}`;
  link.href = url;
  link.setAttribute("download", fullName);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
