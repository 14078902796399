class CurrencyUtils {
  static convert(data: string) {
    if (/^[0-9]+(\.{1}[0-9]*)?$/.test(data)) {
      if (data.includes(".")) {
        const [main, decimal] = data.split(".");
        const mainValue = parseInt(`${main}`).toLocaleString();
        const decimalValue = decimal.length ? `${decimal}`.substring(0, 5) : "";
        return `${mainValue}.${decimalValue}`;
      }
      return parseInt(data).toLocaleString();
    }
    return "";
  }

  static toCurrency(value: number) {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });

    return formatter.format(value);
  }
}

export default CurrencyUtils;
