import { Text, Stack, Box } from "@mantine/core";
import { PaymentStatusBadgeComponent } from "components";
import { usePaymentLink, usePickupRequestInvoices } from "hooks";
import { PaymentStatusComponentProps } from "./types";

export function PaymentStatusComponent(props: PaymentStatusComponentProps) {
  const { pikcupRequest } = props;
  const { invoices } = usePickupRequestInvoices({
    pickupRequestId: pikcupRequest?.id,
  });

  const { paymentLink } = usePaymentLink({
    paymentLinkId: invoices[0]?.paymentLinkId,
  });

  return (
    <Stack spacing={0}>
      <Text size="sm" color="dimmed">
        Payment Status
      </Text>
      <Box>
        <PaymentStatusBadgeComponent paymentLink={paymentLink} />
      </Box>
    </Stack>
  );
}
