import { Box, Navbar, ScrollArea } from "@mantine/core";
import { MenuSectionComponentProps } from "./types";

export function MenuSectionComponent(props: MenuSectionComponentProps) {
  const { children } = props;

  return (
    <Navbar.Section component={ScrollArea} mx="-xs" px="xs" grow>
      <Box>{children}</Box>
    </Navbar.Section>
  );
}
